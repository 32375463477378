import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const MoreVert = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{ width: 24, height: 24 }}>
      <path
        d="M9 19.3333C9 20.806 10.194 22 11.6667 22C13.1393 22 14.3333 20.806 14.3333 19.3333C14.3333 17.8607 13.1393 16.6667 11.6667 16.6667C10.194 16.6667 9 17.8607 9 19.3333ZM9 12C9 13.4727 10.194 14.6667 11.6667 14.6667C13.1393 14.6667 14.3333 13.4727 14.3333 12C14.3333 10.5273 13.1393 9.33333 11.6667 9.33333C10.194 9.33333 9 10.5273 9 12ZM9 4.66667C9 6.13933 10.194 7.33333 11.6667 7.33333C13.1393 7.33333 14.3333 6.13933 14.3333 4.66667C14.3333 3.194 13.1393 2 11.6667 2C10.194 2 9 3.194 9 4.66667Z"
        fill={colors.grey[800]}
      />
    </SvgIcon>
  )
}

export default MoreVert
