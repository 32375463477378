import React from 'react'

import { useTranslation } from 'next-i18next'

import * as S from './SeeAll.styles'

export const SeeAll = ({ text, onClick, ...rest }: { text?: string; onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <S.Link onClick={onClick} {...rest}>
      {text || t('b2c.home.seeAll')}
    </S.Link>
  )
}
