import React from 'react'

import { Trans, useTranslation } from 'next-i18next'

import EventBusyIcon from '@mui/icons-material/EventBusy'
import { Link } from '@mui/material'

import DialogButton from 'src/components/DialogButton'
import EditCalendarIcon from 'src/components/icons/EditCalendar'
import { useDialog } from 'src/components/providers/DialogProvider'
import useIsDesktop from 'src/hooks/useIsDesktop'

import Dialog from '../../Dialog/Dialog'
import { DialogNames } from '../DialogRoot'

import * as S from './ImmutableAppointmentDialog.styles'

export enum ImmutableAppointmentKind {
  Reschedule = 'RESCHEDULE',
  Canceling = 'CANCELING',
}

type ImmutableAppointmentDialogProps = {
  kind: ImmutableAppointmentKind
  phoneNumber: string
}

export const ImmutableAppointmentDialog = ({ kind, phoneNumber }: ImmutableAppointmentDialogProps) => {
  const { t } = useTranslation()
  const { isOpen, closeDialog } = useDialog()
  const isDesktop = useIsDesktop()

  const isReschedule = kind === ImmutableAppointmentKind.Reschedule

  return (
    <Dialog
      open={isOpen}
      icon={isReschedule ? <EditCalendarIcon /> : <EventBusyIcon />}
      dataTestId={DialogNames.ImmutableAppointment}
      onClose={closeDialog}
      title={t(`b2c.immutableAppointmentDialog.${kind.toLowerCase()}.heading`)}
      actions={<DialogButton onClick={closeDialog}>{t('b2c.buttons.close')}</DialogButton>}
    >
      <S.Text>{t(`b2c.immutableAppointmentDialog.${kind.toLowerCase()}.message`)}</S.Text>

      <Trans
        values={{ phoneNumber }}
        i18nKey="b2c.immutableAppointmentDialog.message"
        components={[
          isDesktop ? <>{phoneNumber}</> : <Link variant="Lato Link 2" key="phone" href={`tel:${phoneNumber}`} />,
        ]}
      />
    </Dialog>
  )
}
