import React, { useState } from 'react'

import { useTranslation } from 'next-i18next'

import { RecommendationSpeciality } from '@dentalux/common'
import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Divider, Button, Accordion, Typography } from '@mui/material'

import {
  RecommendationData,
  RecommendationFrequencyUnit,
  SingularRecommendationFrequencyUnit,
} from 'src/@types/Recommendation'
import { CalendarIcon } from 'src/components/icons/Calendar'
import CollapseArrow from 'src/components/icons/CollapseArrow'
import ExpandArrow from 'src/components/icons/ExpandArrow'
import RecommendationStethoscope from 'src/components/icons/RecommendationStethoscope'
import RecommendationTooth from 'src/components/icons/RecommendationTooth'
import Loader from 'src/components/Loader/Loader'
import SwipeableDrawer from 'src/components/SwipeableDrawer'
import { DataTestIds } from 'src/config/dataTestIds'
import { formatShort } from 'src/helpers/date'
import { useBookRecommendation } from 'src/hooks/useBookRecommendation'
import useDateFnsConfig from 'src/hooks/useDateFnsConfig'
import useIsDesktop from 'src/hooks/useIsDesktop'
import useIsMobile from 'src/hooks/useIsMobile'

import { Trait } from '../Trait/Trait'

import * as S from './Recommendation.styles'

const singularFrequency = {
  [RecommendationFrequencyUnit.Hours]: SingularRecommendationFrequencyUnit.Hour,
  [RecommendationFrequencyUnit.Days]: SingularRecommendationFrequencyUnit.Day,
  [RecommendationFrequencyUnit.HalfDays]: SingularRecommendationFrequencyUnit.HalfDay,
  [RecommendationFrequencyUnit.Weeks]: SingularRecommendationFrequencyUnit.Week,
  [RecommendationFrequencyUnit.Months]: SingularRecommendationFrequencyUnit.Month,
  [RecommendationFrequencyUnit.Years]: SingularRecommendationFrequencyUnit.Year,
  [RecommendationFrequencyUnit.Decades]: SingularRecommendationFrequencyUnit.Decade,
}

export const Recommendation = ({
  data,
  onClose,
  width,
}: {
  data: RecommendationData
  onClose: (e) => void
  width: number
}) => {
  const { t } = useTranslation()

  const [showDetails, setShowDetails] = useState(false)
  const [bookLoading, setBookLoading] = useState(false)

  const config = useDateFnsConfig()

  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const isOther = data.speciality !== RecommendationSpeciality.DENTAL

  const Title = ({ inDrawer }: { inDrawer?: boolean }) => (
    <>
      {(inDrawer || !isMobile) && (
        <S.Frame isOther={isOther}>{isOther ? <RecommendationStethoscope /> : <RecommendationTooth />}</S.Frame>
      )}

      <S.Title
        inDrawer={inDrawer}
        variant={inDrawer ? 'DM Serif H4' : isDesktop ? 'Lato H5 Bold' : 'Lato Emphasized 1'}
      >
        {inDrawer && `${t('b2c.recommendations.details')} -`} {t(data.titleTranslationKey)}
      </S.Title>
    </>
  )

  const handleBook = useBookRecommendation({ data })

  const buttonColor = isOther ? 'purple' : 'blue'

  const arrowProps = {
    fill: isDesktop ? colors.grey[800] : buttonColor,
  }

  const lastPerformed = data.treatmentBundles
    .map((el) => el.lastTimePersonHadThatTreatment)
    .filter((el) => el)
    .sort((a, b) => Number(new Date(b)) - Number(new Date(a)))

  const bookable = data?.treatmentBundles?.length > 0

  const onBookClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setBookLoading(true)
    handleBook(e)
  }

  return (
    <S.Root
      Title={<Title />}
      width={width}
      onClose={onClose}
      onClick={isDesktop ? undefined : () => setShowDetails(!showDetails)}
      dataTestId={DataTestIds.RecommendationCard}
      data-speciality={data.speciality}
    >
      <>
        <S.Importance variant={isDesktop ? 'Lato Emphasized 2' : 'Lato Caption Bold'}>
          {t('b2c.recommendations.importance')}
        </S.Importance>

        <S.Emphasize isOther={isOther}>
          <Typography variant={isDesktop ? 'Lato Body 2' : 'Lato Caption'}>
            {t(data.shortDescriptionTranslationKey)}
          </Typography>
        </S.Emphasize>

        <S.Introduction>
          {isOther && (
            <Trait
              value={t(`b2c.recommendations.traits.specialist.${data.speciality}`)}
              label={'b2c.recommendations.traits.speciality'}
            />
          )}

          {lastPerformed.length > 0 && (
            <Trait
              value={formatShort(new Date(lastPerformed[0]), config)}
              label={'b2c.recommendations.traits.lastVisit'}
            />
          )}

          {data?.frequency && (
            <Trait
              value={t('b2c.recommendations.traits.rhythm', {
                unit: t(
                  `b2c.recommendations.traits.frequency${
                    data.frequency.count === 1 ? singularFrequency[data.frequency.unit] : data.frequency.unit
                  }`
                ),
                count: data.frequency.count,
              })}
              label={'b2c.recommendations.traits.frequency'}
            />
          )}

          {data.costTranslationKey && t(data.costTranslationKey) !== '-' && (
            <Trait value={t(data.costTranslationKey)} label={'b2c.recommendations.traits.cost'} />
          )}
        </S.Introduction>
      </>

      <S.Controls>
        {bookable && (
          <Button
            size="sm"
            onClick={onBookClick}
            startIcon={bookLoading ? <Loader center={false} size="xs" /> : <CalendarIcon />}
            color={buttonColor}
            data-testid={DataTestIds.DashboardBookRecommendation}
            variant="bordered"
            fullWidth
            disabled={bookLoading}
          >
            {t('b2c.recommendations.book')}
          </Button>
        )}

        {isDesktop && <Divider />}

        {isDesktop && (
          <Button
            size="md"
            endIcon={
              isDesktop ? showDetails ? <CollapseArrow {...arrowProps} /> : <ExpandArrow {...arrowProps} /> : undefined
            }
            onClick={() => setShowDetails(!showDetails)}
            variant={isDesktop ? 'borderless' : 'shadowed'}
            color={isDesktop ? 'grey' : buttonColor}
          >
            {t('b2c.recommendations.details')}
          </Button>
        )}
      </S.Controls>

      {isDesktop ? (
        <Accordion disableGutters expanded={isDesktop && showDetails}>
          <div />
          <S.Details dangerouslySetInnerHTML={{ __html: t(data.longDescriptionTranslationKey) }} />
        </Accordion>
      ) : (
        <SwipeableDrawer
          onClose={() => setShowDetails(false)}
          closeDrawer={() => setShowDetails(false)}
          open={showDetails}
          header={
            <S.Header>
              <Title />
            </S.Header>
          }
          action={
            bookable && (
              <Button
                color={buttonColor}
                onClick={onBookClick}
                fullWidth
                size="lg"
                data-testid={DataTestIds.DashboardBookRecommendationFromDetils}
                disabled={bookLoading}
                startIcon={bookLoading && <Loader center={false} size="xs" />}
              >
                {t('b2c.recommendations.book')}
              </Button>
            )
          }
        >
          <S.Details inDrawer dangerouslySetInnerHTML={{ __html: t(data.longDescriptionTranslationKey) }} />
        </SwipeableDrawer>
      )}
    </S.Root>
  )
}
