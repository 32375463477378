import React, { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Typography } from '@mui/material'

import { PersonSummary } from 'src/@types/Person'

import usePersonSummary from '../../hooks/usePersonSummary'
import Section from '../Section'

import * as S from './DataSection.styles'

type DentalDataProps = {
  summary: Partial<PersonSummary>
}

export const DataSection = ({ summary }: DentalDataProps) => {
  const { t } = useTranslation()
  const router = useRouter()

  const data = usePersonSummary(summary)
  const active = data.filter((item) => item.active)

  useEffect(() => {
    active.map((item) => router.prefetch(item.link))
  }, [router.prefetch])

  return (
    <Section title={t('b2c.home.subheading.data')}>
      <S.Container length={active.length}>
        {active.map((item) => (
          <S.Item key={item.title} onClick={() => router.push(item.link)}>
            {item.Icon}

            <S.Wrapper>
              <Typography variant="Lato Emphasized 1">{item.title}</Typography>
              <Typography variant="Lato Body 2">{item.text}</Typography>
            </S.Wrapper>
          </S.Item>
        ))}
      </S.Container>
    </Section>
  )
}
