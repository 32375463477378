import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ExpandArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: '24px', height: '24px', ...props.sx }}>
      <path
        d="M12 13.537L16.234 9.303C16.638 8.89901 17.293 8.89901 17.697 9.303C18.101 9.70699 18.101 10.362 17.697 10.766L12.7315 15.7315C12.3275 16.1355 11.6725 16.1355 11.2685 15.7315L6.30299 10.766C5.899 10.362 5.899 9.70699 6.30299 9.303C6.70698 8.89901 7.36198 8.89901 7.76597 9.303L12 13.537Z"
        fill={props.fill || colors.aqua[800]}
      />
    </SvgIcon>
  )
}

export default ExpandArrow
