import React, { ReactNode } from 'react'

import { CSSObject } from '@mui/material'

import { DataTestIds } from 'src/config/dataTestIds'

import * as S from './Card.styles'

export const Card = ({
  children,
  onClick,
  className,
  onClose,
  Title,
  Extra,
  dataTestId,
  headerStyles,
  ...rest
}: {
  className?: string
  children: ReactNode | ReactNode[]
  onClick?: () => void
  onClose?: (e) => void
  Title: ReactNode | ReactNode[]
  Extra?: ReactNode | ReactNode[]
  headerStyles?: CSSObject
  dataTestId?: string
}) => {
  return (
    <S.Root className={className} onClick={onClick} data-testid={dataTestId} {...rest}>
      <S.Wrapper>
        <S.Header headerStyles={headerStyles}>{Title}</S.Header>

        {onClose && <S.CloseButton onClick={onClose} data-testid={DataTestIds.DismissButton} />}

        {Extra && Extra}
      </S.Wrapper>

      {children}
    </S.Root>
  )
}
