import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const IosShare = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
    <path
      d="M12 2C11.7952 2 11.5901 2.07837 11.4336 2.23438L9.0332 4.63477C8.8042 4.86377 8.73833 5.20586 8.86133 5.50586C8.98533 5.80486 9.27661 6 9.59961 6H11V17C10.9981 17.1325 11.0226 17.2641 11.072 17.387C11.1214 17.51 11.1948 17.6219 11.2878 17.7163C11.3809 17.8107 11.4918 17.8856 11.614 17.9368C11.7363 17.9879 11.8675 18.0142 12 18.0142C12.1325 18.0142 12.2637 17.9879 12.386 17.9368C12.5082 17.8856 12.6191 17.8107 12.7122 17.7163C12.8052 17.6219 12.8786 17.51 12.928 17.387C12.9774 17.2641 13.0019 17.1325 13 17V6H14.4004C14.7234 6 15.0147 5.80486 15.1387 5.50586C15.1797 5.40686 15.1992 5.30222 15.1992 5.19922C15.1992 4.99122 15.1198 4.78877 14.9668 4.63477L12.5664 2.23438C12.4099 2.07837 12.2048 2 12 2ZM6 7C4.90694 7 4 7.90694 4 9V20C4 21.0931 4.90694 22 6 22H18C19.0931 22 20 21.0931 20 20V9C20 7.90694 19.0931 7 18 7H17C16.8675 6.99813 16.7359 7.02261 16.613 7.07202C16.49 7.12143 16.3781 7.1948 16.2837 7.28784C16.1893 7.38088 16.1144 7.49176 16.0632 7.61401C16.0121 7.73627 15.9858 7.86748 15.9858 8C15.9858 8.13252 16.0121 8.26373 16.0632 8.38599C16.1144 8.50824 16.1893 8.61912 16.2837 8.71216C16.3781 8.8052 16.49 8.87857 16.613 8.92798C16.7359 8.97739 16.8675 9.00187 17 9H18V20H6V9H7C7.13251 9.00187 7.26407 8.97739 7.38704 8.92798C7.51001 8.87857 7.62193 8.8052 7.7163 8.71216C7.81067 8.61912 7.88561 8.50824 7.93676 8.38599C7.9879 8.26373 8.01424 8.13252 8.01424 8C8.01424 7.86748 7.9879 7.73627 7.93676 7.61401C7.88561 7.49176 7.81067 7.38088 7.7163 7.28784C7.62193 7.1948 7.51001 7.12143 7.38704 7.07202C7.26407 7.02261 7.13251 6.99813 7 7H6Z"
      fill={colors.grey[800]}
    />
  </SvgIcon>
)

export default IosShare
