import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Close = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
    <path
      d="M4.9902 3.99018C4.79126 3.99023 4.59687 4.04961 4.43186 4.16073C4.26685 4.27184 4.13872 4.42964 4.06387 4.61395C3.98901 4.79826 3.97082 5.00071 4.01162 5.19542C4.05243 5.39012 4.15037 5.56823 4.29293 5.70698L10.5859 11.9999L4.29293 18.2929C4.19696 18.3851 4.12033 18.4954 4.06755 18.6176C4.01476 18.7397 3.98688 18.8711 3.98553 19.0042C3.98417 19.1372 4.00938 19.2692 4.05967 19.3924C4.10996 19.5155 4.18432 19.6274 4.2784 19.7215C4.37248 19.8156 4.48438 19.89 4.60756 19.9402C4.73073 19.9905 4.86271 20.0157 4.99575 20.0144C5.12879 20.013 5.26022 19.9851 5.38235 19.9324C5.50448 19.8796 5.61485 19.803 5.70699 19.707L12 13.414L18.2929 19.707C18.3851 19.803 18.4954 19.8796 18.6176 19.9324C18.7397 19.9851 18.8711 20.013 19.0042 20.0144C19.1372 20.0157 19.2692 19.9905 19.3924 19.9402C19.5155 19.89 19.6275 19.8156 19.7215 19.7215C19.8156 19.6274 19.89 19.5155 19.9403 19.3924C19.9906 19.2692 20.0158 19.1372 20.0144 19.0042C20.0131 18.8711 19.9852 18.7397 19.9324 18.6176C19.8796 18.4954 19.803 18.3851 19.707 18.2929L13.414 11.9999L19.707 5.70698C19.8515 5.56652 19.9502 5.38567 19.9902 5.18815C20.0301 4.99063 20.0095 4.78565 19.9309 4.60007C19.8524 4.41448 19.7196 4.25695 19.55 4.14812C19.3804 4.03929 19.1819 3.98424 18.9804 3.99018C18.7206 3.99792 18.4741 4.1065 18.2929 4.29292L12 10.5859L5.70699 4.29292C5.6138 4.19712 5.50236 4.12098 5.37924 4.06898C5.25613 4.01698 5.12384 3.99019 4.9902 3.99018Z"
      fill={colors.grey[700]}
    />
  </SvgIcon>
)

export default Close
