import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'center',
})<{ center?: boolean }>(({ theme, center }) => ({
  ...theme.typography['Lato Link 2'],

  ...(center && { margin: 'auto' }),
}))

LinkButton.defaultProps = {
  variant: 'borderless',
  type: 'button',
}
