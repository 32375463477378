import { styled } from '@mui/material/styles'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'length',
})<{ length: number }>(({ length, theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: `repeat(${length === 1 ? 1 : 2}, 1fr)`,
    gap: theme.spacing(3),
  },
}))

export const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  background: theme.palette.grey[0],
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[3],
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6, 2),
  },
}))

export const Wrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))
