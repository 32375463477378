import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export type PillColorType = keyof typeof colors

export const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'uppercase',
})<{ color?: PillColorType; uppercase?: boolean }>(({ theme, color = 'blue1', uppercase }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: colors[color][color === 'grey' ? '800' : '900'],
  padding: theme.spacing(0.5, 1),
  backgroundColor: colors[color][color === 'grey' ? '100' : '50'],
  borderRadius: theme.spacing(1),
  ...theme.typography['Lato Caption Small Bold'],
  textTransform: uppercase ? 'uppercase' : 'unset',
  height: theme.spacing(2.5),
}))

Root.defaultProps = { role: 'button' }
