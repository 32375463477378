import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const RecommendationTooth = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 10" sx={{ width: 12, height: 10 }}>
      <path
        d="M3.99998 7.7799L1.21998 4.9999L0.273315 5.9399L3.99998 9.66656L12 1.66656L11.06 0.726562L3.99998 7.7799Z"
        fill={colors.grey[0]}
      />
    </SvgIcon>
  )
}

export default RecommendationTooth
