import React, { useEffect, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Box, Typography } from '@mui/material'

import { parseISO } from 'date-fns'
import { AppointmentType } from 'src/@types/Appointment'
import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { BookingPurpose } from 'src/@types/Booking'
import { AppointmentCardAction } from 'src/components/AppointmentCard/AppointmentCard'
import AppointmentView from 'src/components/AppointmentView'
import FlipCalendar from 'src/components/icons/FlipCalendar'
import Loader from 'src/components/Loader/Loader'
import PractitionerDetails from 'src/components/PractitionerDetails'
import {
  setAppointmentBundle,
  setBookingIds,
  setDefaultBookingContext,
  setManipulatedAppointment,
  setTreatmentTitle,
  useBookings,
  useBookingsDispatch,
} from 'src/components/providers/BookingsProvider'
import SwipeableDrawer from 'src/components/SwipeableDrawer'
import { SwipeStack } from 'src/components/SwipeStack/Book'
import { formatMedium, formatTime } from 'src/helpers/date'
import getPractitionerRoles from 'src/helpers/getPractitionerRoles'
import isLessThanXHours from 'src/helpers/isLessThanXHours'
import useDateFnsConfig from 'src/hooks/useDateFnsConfig'
import useElementSize from 'src/hooks/useElementSize'
import useIsDesktop from 'src/hooks/useIsDesktop'
import useIsMobile from 'src/hooks/useIsMobile'
import useManagement from 'src/hooks/useManagement'
import { AppointmentIdsEntity } from 'src/screens/Appointments/Management/Management.helpers'

import Section from '../Section'
import { SeeAll } from '../SeeAll/SeeAll'

import * as S from './AppointmentSection.styles'

type AppointmentSectionProps = {
  processing: boolean
  appointments: AppointmentBundle[]
}

const AppointmentSection = ({ processing, appointments }: AppointmentSectionProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  const [stackRef, { width }] = useElementSize()
  const { initView, initCancel, initReschedule } = useManagement()
  const dispatch = useBookingsDispatch()
  const isMobile = useIsMobile()

  const { appointmentBundle: selectedAppointmentBundle } = useBookings()

  const { t } = useTranslation()
  const config = useDateFnsConfig()
  const isDesktop = useIsDesktop()
  const router = useRouter()

  const seeAllRedirect = `/appointments/${appointments.length === 0 ? 'past' : ''}`
  const appointmentsRedirect = '/appointments'
  const bookRedirect = '/appointments/book'

  useEffect(() => {
    router.prefetch(seeAllRedirect)
    router.prefetch(appointmentsRedirect)
    router.prefetch(bookRedirect)
  }, [router.prefetch])

  const handleCancel = (appointmentBundle: AppointmentBundle) => {
    router.push(appointmentsRedirect).then(() => initCancel({ appointmentBundle }))
  }

  const handleReschedule = (appointmentBundle: AppointmentBundle) => {
    dispatch(setDefaultBookingContext())

    if (
      isLessThanXHours(appointmentBundle.start) ||
      !appointmentBundle.mainPractitioner ||
      !appointmentBundle.bookableByPatient ||
      !appointmentBundle.canBeCancelled ||
      isDesktop
    ) {
      return router.push(appointmentsRedirect).then(() => initReschedule({ appointmentBundle }))
    }

    dispatch(
      setManipulatedAppointment({
        start: appointmentBundle.start,
        practitioner: appointmentBundle.mainPractitioner.name,
      })
    )
    dispatch(setTreatmentTitle(appointmentBundle.treatmentTitle))
    dispatch(setBookingIds(AppointmentIdsEntity.fromRest(appointmentBundle, BookingPurpose.Reschedule)))

    router.push(bookRedirect)
  }

  const handleDetailedView = (appointmentBundle: AppointmentBundle) => {
    if (isDesktop) return router.push(appointmentsRedirect).then(() => initView({ appointmentBundle }))

    dispatch(setAppointmentBundle(appointmentBundle))
    setDrawerOpen(true)
  }

  const content = (() => {
    if (processing) return <Loader size="lg" />

    if (appointments.length > 0) {
      const appointmentBundles = isMobile ? [...appointments] : appointments.slice(0, 1)

      return (
        <SwipeStack ref={stackRef}>
          {appointmentBundles.map((appointmentBundle) => {
            const startDate = parseISO(appointmentBundle.start)
            const { mainPractitioner, clinic } = appointmentBundle

            return (
              <Box
                key={appointmentBundle.referenceId}
                sx={{ minWidth: isMobile && appointments.length > 1 ? `calc(${width}px - 16px)` : '100%' }}
              >
                <S.AppointmentCard
                  key={appointmentBundle.referenceId}
                  type={AppointmentType.Upcoming}
                  referenceId={appointmentBundle.referenceId}
                  onClick={() => handleDetailedView(appointmentBundle)}
                  title={formatMedium(startDate, config)}
                  extra={[
                    formatTime(startDate, config),
                    appointmentBundle.durationInMinutes ? ` | ${appointmentBundle.durationInMinutes}m` : null,
                  ]}
                  actionBar={{
                    actions: [AppointmentCardAction.Cancel, AppointmentCardAction.Reschedule],
                    onReschedule: () => handleReschedule(appointmentBundle),
                    onCancel: () => handleCancel(appointmentBundle),
                  }}
                >
                  <PractitionerDetails
                    title={appointmentBundle.treatmentTitle}
                    subTitle={clinic.officialName}
                    description={
                      mainPractitioner
                        ? `${mainPractitioner.name} | ${getPractitionerRoles(mainPractitioner, t)}`
                        : undefined
                    }
                  />
                </S.AppointmentCard>
              </Box>
            )
          })}
        </SwipeStack>
      )
    }

    if (!processing) {
      return (
        <S.NoAppointments>
          <FlipCalendar />
          <Typography variant="Lato Emphasized 2">{t('b2c.appointments.noUpcomingAppointments')}</Typography>
        </S.NoAppointments>
      )
    }

    return null
  })()

  return (
    <Section
      title={t('b2c.home.subheading.appointment')}
      extra={
        <SeeAll
          onClick={() => {
            dispatch(setDefaultBookingContext())
            router.push(seeAllRedirect)
          }}
        />
      }
    >
      {content}
      <SwipeableDrawer
        onClose={() => dispatch(setAppointmentBundle(null))}
        closeDrawer={() => setDrawerOpen(false)}
        open={drawerOpen && !isDesktop}
      >
        {!!selectedAppointmentBundle && (
          <AppointmentView
            type={AppointmentType.Upcoming}
            clinic={selectedAppointmentBundle.clinic}
            treatmentTitle={selectedAppointmentBundle.treatmentTitle}
            practitioner={selectedAppointmentBundle.mainPractitioner}
            dateOfVisit={selectedAppointmentBundle.start}
          />
        )}
      </SwipeableDrawer>
    </Section>
  )
}

export default AppointmentSection
