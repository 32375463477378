import { gql } from '@apollo/client'

import { ClinicsFragments } from './clinics'
import { PractitionerFragments } from './practitioner'

export const APPOINTMENT_BUNDLES_QUERY = gql`
  query APPOINTMENT_BUNDLES($personReferenceId: UUID!, $kind: AppointmentBundleKind!, $page: Int!, $size: Int!) {
    appointmentBundles(id: $personReferenceId, kind: $kind, page: $page, size: $size) {
      pageInfo {
        totalElements
      }
      data {
        appointments {
          referenceId
        }
        referenceId
        canceled
        start
        treatmentBundleReferenceId
        treatmentTitle
        durationInMinutes
        bookableByPatient
        canBeCancelled
        clinic {
          ...Clinic
        }
        mainPractitioner {
          ...Practitioner
        }
      }
    }
  }
  ${ClinicsFragments.Clinic}
  ${PractitionerFragments.Practitioner}
`
