import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const EditCalendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{ width: 24, height: 24 }}>
      <path
        d="M7 1C6.448 1 6 1.448 6 2V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12.998C13.263 21 13.5181 20.895 13.7051 20.707C14.3351 20.077 13.889 19 12.998 19H5V8H19V12.998C19 13.889 20.077 14.3351 20.707 13.7051C20.895 13.5181 21 13.263 21 12.998V5C21 3.9 20.1 3 19 3H18V2C18 1.448 17.552 1 17 1C16.448 1 16 1.448 16 2V3H8V2C8 1.448 7.552 1 7 1ZM22.5859 15C22.2239 15 21.8619 15.1381 21.5859 15.4141L20.8535 16.1465L22.8535 18.1465L23.5859 17.4141C24.1379 16.8621 24.1379 15.9661 23.5859 15.4141C23.3099 15.1381 22.9479 15 22.5859 15ZM20.1465 16.8535L15.1465 21.8535C15.0535 21.9475 15 22.074 15 22.207V23.5C15 23.776 15.224 24 15.5 24H16.793C16.925 24 17.0525 23.9465 17.1465 23.8535L22.1465 18.8535L20.1465 16.8535Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default EditCalendar
