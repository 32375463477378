import React, { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Box } from '@mui/material'

import CircularProgressbar from 'src/components/CircularProgressbar'
import ConditionGradient from 'src/components/ConditionGradient'
import { DentalIQBeta } from 'src/components/DentalIQBeta/DentalIQBeta'
import { DataTestIds } from 'src/config/dataTestIds'
import usePatientScoring from 'src/hooks/api/usePatientScoring'

import Section from '../Section'
import { SeeAll } from '../SeeAll/SeeAll'

import * as S from './DentalIQ.styles'

const DentalIQ = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { data } = usePatientScoring()

  const dentalIQ = data?.[0]

  const dentalIQRedirect = '/dental-iq'

  const score = dentalIQ ? Math.round(dentalIQ.overallScore.score) : 0
  const conditionsScore = dentalIQ ? dentalIQ.conditions.teeth.score : 0
  const gumsScore = dentalIQ ? dentalIQ.conditions.gums.score : 0

  useEffect(() => {
    router.prefetch(dentalIQRedirect)
  }, [router.prefetch])

  return (
    <Section
      show={!!dentalIQ}
      title={
        <S.Frame>
          {t('b2c.home.subheading.dentalIQ')}
          <DentalIQBeta tooltipPlacement="top" />
        </S.Frame>
      }
      extra={
        <SeeAll
          data-testid={DataTestIds.DentalIQLearnMore}
          text={t('b2c.home.learnMore')}
          onClick={() => {
            router.push(dentalIQRedirect)
          }}
        />
      }
    >
      <Box onClick={() => router.push(dentalIQRedirect)}>
        <S.Card data-testid={DataTestIds.DentalIQDashboardWidget}>
          <CircularProgressbar value={score} label={t('b2c.dentalIQ.score')} compact />

          <S.Conditions>
            <S.Item flex={1}>
              <ConditionGradient value={conditionsScore} compact label={t('b2c.dentalIQ.labels.teethCondition')} />
            </S.Item>
            <S.Item flex={0}>
              <S.Divider />
            </S.Item>
            <S.Item flex={1}>
              <ConditionGradient value={gumsScore} compact label={t('b2c.dentalIQ.labels.gumCondition')} />
            </S.Item>
          </S.Conditions>
        </S.Card>
      </Box>
    </Section>
  )
}

export default DentalIQ
