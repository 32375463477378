import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box, IconButton as BaseIconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Card } from '../Card/Card'

export const Root = styled(Card)<{ width: number }>(({ theme, width }) => ({
  minWidth: `calc(${width}px - ${theme.spacing(2)})`,

  [theme.breakpoints.up('sm')]: {
    minWidth: '100%',
  },
}))

export const Book = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  marginTop: theme.spacing(2),
  padding: theme.spacing(2, 0),
  background: theme.palette.common.white,
}))

export const Frame = styled(Box)<{ isOther: boolean }>(({ isOther, theme }) => ({
  width: 32,
  height: 32,
  flexShrink: 0,
  display: 'flex',
  marginRight: theme.spacing(1.5),
  alignItems: 'center',
  justifyContent: 'center',
  background: isOther ? colors.purple[700] : colors.blue2[700],
  borderRadius: theme.spacing(1),
}))

export const Title = styled(Typography, { shouldForwardProp: (prop: string) => 'inDrawer' !== prop })<{
  inDrawer: boolean
}>(({ inDrawer, theme }) => ({
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  ...(inDrawer && { color: theme.palette[800] }),
}))

export const IconButton = styled(BaseIconButton)({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
})

IconButton.defaultProps = {
  disableRipple: true,
}

export const Importance = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: colors.grey[800],
}))

export const Emphasize = styled(Box)<{ isOther: boolean }>(({ theme, isOther }) => ({
  borderRadius: theme.spacing(1),
  background: isOther ? colors.purple[50] : colors.blue2[50],
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

export const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
}))

export const Controls = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const Trait = styled(Box)({
  color: colors.grey[800],
})

export const Value = styled(Typography)(({ theme }) => ({
  display: 'inline',
  ...theme.typography['Lato Emphasized 2'],
}))

export const Details = styled(Typography)<{ inDrawer?: boolean }>(({ theme, inDrawer }) => ({
  marginTop: theme.spacing(inDrawer ? 0 : 2),
  color: theme.palette.grey[700],

  ul: {
    paddingLeft: theme.spacing(3),
  },
}))

Details.defaultProps = {
  variant: 'Lato Body 2',
}

export const Wrapper = styled(Box)({})

Wrapper.defaultProps = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: 2,
}

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginBottom: 0,
  flex: 1,
})
