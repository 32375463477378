import React, { useEffect, useState } from 'react'

import { Fade } from '@mui/material'

import * as S from './CircularProgressbar.styles'

const CircularProgressbar = ({
  value = 0,
  label,
  compact = false,
}: {
  value: number
  label?: string
  compact?: boolean
}) => {
  const [counter, setCounter] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (value !== 0) {
      setProgress(value)
    }
  }, [value])

  useEffect(() => {
    if (progress === 0) return

    const timer = setInterval(() => {
      setCounter((prev) => {
        if (prev === progress) {
          clearInterval(timer)
        }

        return prev === progress ? prev : prev + 1
      })
    }, 10)

    return () => {
      clearInterval(timer)
    }
  }, [progress])

  const size = compact ? 88 : 112
  const coordinates = size / 2
  const strokeWidth = coordinates * 0.2
  const radius = coordinates - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  return (
    <S.Root width={size} height={size}>
      <S.Progress width={size} height={size}>
        <S.CircleBack
          r={radius}
          cx={coordinates}
          cy={coordinates}
          strokeWidth={strokeWidth}
          sx={{
            strokeDasharray: circumference,
            strokeDashoffset: 0,
          }}
        />
        <S.CircleFront
          r={radius}
          cx={coordinates}
          cy={coordinates}
          strokeWidth={strokeWidth}
          sx={{
            strokeDasharray: circumference,
            strokeDashoffset: circumference - circumference * (progress / 100),
          }}
        />
      </S.Progress>
      <S.Frame width={size} height={size}>
        <Fade in timeout={1000}>
          <S.Value compact={compact}>{counter}%</S.Value>
        </Fade>
        <Fade in timeout={2000}>
          <S.Label>{label}</S.Label>
        </Fade>
      </S.Frame>
    </S.Root>
  )
}

export default CircularProgressbar
