import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Text = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

Text.defaultProps = {
  variant: 'Lato Body 2',
}
