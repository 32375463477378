import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Typography, Box, IconButton as BaseIconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Card as CardBase } from '../Card/Card'

export const Text = styled(Typography)({
  color: colors.grey[700],
})

Text.defaultProps = {
  variant: 'Lato Body 2',
}

export const Image = styled('img')({
  height: 'auto',
  objectFit: 'contain',
})

Image.defaultProps = {
  src: '/privadent/meditation.png',
}

export const Card = styled(CardBase)({
  backgroundColor: colors.blue1[50],
})

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}))

export const IconButton = styled(BaseIconButton)({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
})
