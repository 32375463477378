import React from 'react'

import { Book } from 'src/components/Book/Book'
import { EndBar } from 'src/components/EndBar/EndBar'
import { useDialog } from 'src/components/providers/DialogProvider'
import { DataTestIds } from 'src/config/dataTestIds'
import useDashboard from 'src/hooks/useDashboard'
import Dashboard from 'src/screens/Dashboard'
import withTranslation from 'src/staticProps/withTranslation'
import InternalTemplate from 'src/templates/InternalTemplate'

const Page = () => {
  const { person, personSummary, supplementaryInsurance, appointments, recommendations, isLoading } = useDashboard({
    onlyRefetch: false,
  })

  const dialog = useDialog()

  return (
    <InternalTemplate
      Prefooter={
        !dialog.dialogType ? (
          <EndBar>
            <Book dataTestId={DataTestIds.DashboardBook} />
          </EndBar>
        ) : undefined
      }
    >
      <Dashboard
        supplementaryInsurance={supplementaryInsurance}
        person={person}
        personSummary={personSummary}
        recommendations={recommendations}
        appointments={appointments}
        isLoading={isLoading}
      />
    </InternalTemplate>
  )
}

export const getStaticProps = withTranslation

export default Page
