import React, { ReactNode } from 'react'

import { useTranslation } from 'next-i18next'

import { AppointmentType } from 'src/@types/Appointment'
import { DataTestIds } from 'src/config/dataTestIds'

import * as S from './AppointmentCard.styles'

export enum AppointmentCardAction {
  Book = 'BOOK',
  Cancel = 'CANCEL',
  Reschedule = 'RESCHEDULE',
}

export type AppointmentCardProps = {
  type?: AppointmentType
  title: string
  extra?: ReactNode
  selected?: boolean
  children?: ReactNode
  showSelectedArrow?: boolean
  width?: number
  onClick?: (data: unknown) => void
  referenceId?: string
  actionBar?: {
    onBook?: () => void
    onCancel?: () => void
    onReschedule?: () => void
    actions: AppointmentCardAction[]
  }
}

const AppointmentCard = ({
  type,
  title,
  children,
  selected,
  extra,
  showSelectedArrow,
  onClick,
  referenceId,
  actionBar,
  ...rest
}: AppointmentCardProps) => {
  const { t } = useTranslation()

  const buttons = actionBar?.actions?.map((action) => {
    if (actionBar?.onBook && action === AppointmentCardAction.Book) {
      return (
        <S.Button
          key={referenceId + 'book'}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            actionBar?.onBook()
          }}
        >
          {t('b2c.appointments.book_new')}
        </S.Button>
      )
    }

    if (action === AppointmentCardAction.Reschedule) {
      return (
        <S.Button
          key={referenceId + 'reschedule'}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            actionBar?.onReschedule()
          }}
        >
          {t('b2c.appointments.reschedule')}
        </S.Button>
      )
    }

    if (action === AppointmentCardAction.Cancel) {
      return (
        <S.Button
          key={referenceId + 'cancel'}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            actionBar?.onCancel()
          }}
        >
          {t('b2c.buttons.cancel')}
        </S.Button>
      )
    }
  })

  const renderButton = (button, index) => <S.Wrapper key={`${index}_action_button`}>{button}</S.Wrapper>

  return (
    <S.Root
      type={type}
      data-testid={DataTestIds.AppointmentCard}
      data-referenceid={referenceId}
      data-canceled={type === AppointmentType.Canceled}
      onClick={onClick ? onClick : undefined}
      selected={selected}
      {...rest}
    >
      <S.Header type={type} selected={selected}>
        <S.Meta>
          <S.Title>{title}</S.Title>
        </S.Meta>
        <S.MetaExtra>{extra}</S.MetaExtra>
        {showSelectedArrow && <S.ArrowIcon visible={selected} />}
      </S.Header>

      <S.Content>{children}</S.Content>

      {buttons.filter((b) => !!b).length > 0 && <S.Actions>{buttons.map(renderButton)}</S.Actions>}
    </S.Root>
  )
}

export default AppointmentCard
