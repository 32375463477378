import React, { ReactNode } from 'react'

type SectionProps = {
  title: ReactNode
  children: ReactNode
  extra?: ReactNode
  show?: boolean
}

import useTypographyVariants from '../../hooks/useTypographyVariants'

import * as S from './Section.styles'

const Section = ({ title, children, extra, show = true }: SectionProps) => {
  const variants = useTypographyVariants()

  if (!show) return null

  return (
    <S.Root>
      <S.Header>
        <S.Title variant={variants.subheading}>{title}</S.Title>

        {extra}
      </S.Header>

      <S.Content>{children}</S.Content>
    </S.Root>
  )
}

export default Section
