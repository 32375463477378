import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { styled } from '@mui/material/styles'

import PillBase from 'src/components/Pill'

export const Root = styled(PillBase)(({ theme }) => ({
  color: theme.palette.common.white,
  background: colors.orange[800],
  height: 24,
  borderRadius: 100,
}))
