import { Box, Button, svgIconClasses, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import BaseAddToHomeScreen from 'src/components/icons/AddToHomeScreen'

export const Title = styled(Typography)({})

Title.defaultProps = { variant: 'DM Serif H4' }

export const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}))

export const List = styled(Box)(({ theme }) => ({
  display: 'grid',
  margin: theme.spacing(3, 0),
  gap: theme.spacing(2),
}))

export const Item = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bold',
})<{ bold?: boolean }>(({ theme, bold }) => ({
  display: 'flex',
  alignItems: 'center',

  ...theme.typography[bold ? 'Lato Emphasized 1' : 'Lato Body 1'],

  [`.${svgIconClasses.root}`]: {
    margin: theme.spacing(0, 0.5),
  },
}))

export const HomeScreenIcon = styled(BaseAddToHomeScreen)({})

HomeScreenIcon.defaultProps = {
  style: {
    marginLeft: 0,
  },
}

export const DissmisButton = styled(Button)({})

DissmisButton.defaultProps = {
  fullWidth: true,
  variant: 'borderless',
}
