import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)({
  position: 'relative',
  margin: 'auto',
})

export const Frame = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Value = styled(Typography)<{ compact?: boolean }>(({ theme, compact }) => ({
  color: theme.palette.grey[700],
  ...theme.typography[compact ? 'Lato Emphasized 1' : 'Lato H4 Bold'],
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}))

Label.defaultProps = { variant: 'Lato Caption Small' }

export const Progress = styled('svg')({
  position: 'absolute',
  transform: 'rotate(-90deg)',
})

export const CircleBack = styled('circle')({
  fill: 'none',
  stroke: colors.grey[300],
  strokeLinecap: 'round',
})

export const CircleFront = styled('circle')({
  fill: 'none',
  stroke: colors.blue2[500],
  strokeLinecap: 'round',
  transition: 'stroke-dashoffset ease-in-out 500ms',
})
