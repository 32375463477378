import React from 'react'

import { AnamnesisWarning } from './components/AnamnesisWarning/AnamnesisWarning'
import { CostPlansWarning } from './components/CostPlansWarning/CostPlansWarning'
import { InvoicesWarning } from './components/InvoicesWarning/InvoicesWarning'
import * as S from './Warnings.styles'

export const Warnings = () => (
  <S.Root>
    <CostPlansWarning />
    <AnamnesisWarning />
    <InvoicesWarning />
  </S.Root>
)
