import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import AppointmentCardBase from 'src/components/AppointmentCard'

export const Paragraph = styled(Typography)(() => ({}))

export const Roles = styled(Paragraph)(({ theme }) => ({
  color: theme.palette.grey[600],
}))

export const NoAppointments = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  filter: 'drop-shadow(0px 0px 10px rgba(33, 33, 33, 0.1)) drop-shadow(0px 1px 1px rgba(33, 33, 33, 0.06))',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[0],
  borderRadius: theme.spacing(1),

  svg: {
    marginRight: theme.spacing(2),
  },
}))

export const BookAppointment = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  maxWidth: 384,
}))

export const AppointmentCard = styled(AppointmentCardBase)(() => ({
  height: '100%',
}))

Paragraph.defaultProps = { variant: 'Lato Body 1' }
