import { gql } from '@apollo/client'

import { PractitionerFragments } from './practitioner'

export const PRACTITIONERS_AVAILABILITIES_QUERY = gql`
  query PRACTITIONERS_AVAILABILITIES(
    $clinicReferenceId: UUID!
    $treatmentBundleReferenceId: UUID!
    $personReferenceId: UUID!
  ) {
    practitionersAvailabilities(
      personReferenceId: $personReferenceId
      clinicReferenceId: $clinicReferenceId
      treatmentBundleReferenceId: $treatmentBundleReferenceId
    ) {
      lastVisitedDate
      nextSlotAvailable
      practitioner {
        ...Practitioner
        rating(personReferenceId: $personReferenceId) {
          ...PractitionerRating
        }
        favorite(personReferenceId: $personReferenceId)
      }
    }
  }
  ${PractitionerFragments.Practitioner}
  ${PractitionerFragments.PractitionerRating}
`
