import { styled } from '@mui/material/styles'

export const Root = styled('div')(({ theme }) => ({
  maxWidth: 552,
  margin: '0 auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))
