import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const DentalFindings = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32" sx={{ width: 32, height: 32 }}>
    <path
      d="M30.1067 26.2459L25.0045 21.3424C24.9346 21.2753 24.8415 21.2378 24.7447 21.2378C24.6478 21.2378 24.5547 21.2753 24.4848 21.3424L23.3036 22.4778L21.332 20.583L20.8125 21.1238L22.7625 22.9978L21.5891 24.1255C21.5527 24.1605 21.5237 24.2025 21.5039 24.249C21.4841 24.2954 21.4739 24.3454 21.4739 24.3959C21.4739 24.4464 21.4841 24.4964 21.5039 24.5429C21.5237 24.5893 21.5527 24.6313 21.5891 24.6663L26.6937 29.5717C27.1548 30.0074 27.7651 30.25 28.3994 30.2497C29.0337 30.2494 29.6439 30.0062 30.1045 29.5701C30.1362 29.5402 30.167 29.5094 30.1968 29.4779C30.6125 29.0369 30.8365 28.4491 30.8196 27.8433C30.8027 27.2374 30.5463 26.663 30.1067 26.2459Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M12.5404 21.6409C17.705 21.6409 21.9066 17.6097 21.9066 12.6542C21.9066 7.69879 17.705 3.6676 12.5404 3.6676C7.37588 3.6676 3.17432 7.69898 3.17432 12.6542C3.17432 17.6095 7.37594 21.6409 12.5404 21.6409ZM7.70251 9.51723C7.85709 8.98411 8.19572 8.52308 8.65819 8.2161C9.17876 7.8756 9.82426 7.75129 10.5769 7.84648C11.2061 7.92617 11.9211 8.32029 12.3941 8.58104C12.4462 8.60979 12.4953 8.63685 12.5399 8.66104C12.7408 8.57486 12.9349 8.47377 13.1207 8.3586C13.4568 8.14489 13.8247 7.98607 14.2108 7.8881C16.3968 7.31935 17.1393 8.7881 17.3148 9.2521C17.7663 10.4454 17.37 11.8475 16.4494 12.314C15.9928 12.5452 15.6931 12.7148 15.609 12.8442C15.5506 12.9343 15.5631 13.1106 15.5947 13.4692C15.6009 13.5382 15.6072 13.6122 15.6134 13.6917C15.6606 14.2809 15.7309 16.2855 14.6536 17.348C14.5721 17.4228 14.4744 17.4778 14.3681 17.5085C14.1993 17.565 14.0157 17.5581 13.8517 17.4891C13.6876 17.4201 13.5543 17.2937 13.4766 17.1335C13.4192 17.0079 13.4009 16.8679 13.424 16.7317C13.4408 16.5555 13.6254 14.3789 12.6854 13.2592C12.668 13.2414 12.6472 13.2272 12.6242 13.2175C12.6012 13.2078 12.5766 13.2028 12.5516 13.2027C12.5267 13.2027 12.502 13.2076 12.479 13.2171C12.456 13.2267 12.4351 13.2407 12.4175 13.2584C11.9068 13.8517 11.7283 14.7604 11.6684 15.4181C11.6286 15.8548 11.627 16.2942 11.6637 16.7312C11.6869 16.8676 11.6686 17.0079 11.6111 17.1337C11.5535 17.2582 11.4611 17.3633 11.3451 17.4366C11.2292 17.5098 11.0945 17.548 10.9574 17.5466C10.8767 17.5465 10.7965 17.5336 10.7199 17.5083C10.6134 17.4779 10.5155 17.4229 10.4343 17.3477C9.35688 16.2852 9.42713 14.2805 9.47432 13.6915C9.48057 13.6116 9.48726 13.5373 9.49307 13.4679C9.52432 13.1097 9.53719 12.9334 9.47869 12.8433C9.39476 12.7137 9.09501 12.5447 8.63857 12.314C7.76857 11.8742 7.35719 10.6457 7.70251 9.51723Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M8.9777 11.6448C9.61395 11.9663 9.92826 12.1568 10.1089 12.4353C10.3104 12.7462 10.2813 13.0765 10.2411 13.5339C10.2351 13.6014 10.2286 13.6737 10.2223 13.7515C10.1406 14.772 10.2816 16.086 10.9139 16.7652C10.8774 16.3563 10.7299 14.0702 11.8494 12.7694C11.9379 12.6693 12.0468 12.5892 12.1688 12.5347C12.2907 12.4801 12.423 12.4522 12.5566 12.453C12.6902 12.4537 12.8221 12.483 12.9435 12.539C13.0649 12.5949 13.1729 12.6761 13.2603 12.7772C14.3572 14.0838 14.2108 16.3585 14.1746 16.7652C14.8069 16.0863 14.9479 14.7722 14.8663 13.7515C14.86 13.674 14.8538 13.602 14.8475 13.5348C14.8073 13.0773 14.7782 12.7468 14.9797 12.4361C15.16 12.158 15.4742 11.9673 16.1103 11.645C16.6848 11.354 16.9244 10.3399 16.6133 9.51753C16.4229 9.01447 15.8825 8.22828 14.3996 8.61391C14.0815 8.69463 13.7786 8.82636 13.5026 9.00397L13.4823 9.01603C13.6487 9.04954 13.8199 9.05211 13.9872 9.02359C14.085 9.00536 14.186 9.02672 14.2681 9.08299C14.3501 9.13925 14.4065 9.2258 14.4247 9.32359C14.4429 9.42139 14.4216 9.52243 14.3653 9.60448C14.309 9.68652 14.2225 9.74286 14.1247 9.76109C13.4606 9.88472 12.8073 9.62528 12.3734 9.41934C12.2833 9.37653 12.1668 9.31234 12.0321 9.23809C11.6091 9.00484 10.9696 8.65241 10.4828 8.59078C9.98714 8.52797 8.79383 8.51416 8.41964 9.73691C8.18626 10.5 8.44176 11.3739 8.9777 11.6448Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M12.5407 23.5581C18.8052 23.5581 23.9018 18.6667 23.9018 12.6542C23.9018 6.6418 18.8052 1.75037 12.5407 1.75037C6.27619 1.75037 1.17969 6.64187 1.17969 12.6542C1.17969 18.6666 6.27619 23.5581 12.5407 23.5581ZM12.5407 2.91762C18.1188 2.91762 22.6569 7.28543 22.6569 12.6542C22.6569 18.0231 18.1188 22.3909 12.5407 22.3909C6.96256 22.3909 2.42456 18.0231 2.42456 12.6542C2.42456 7.28537 6.96256 2.91762 12.5407 2.91762Z"
      fill={colors.aqua[800]}
    />
  </SvgIcon>
)

export default DentalFindings
