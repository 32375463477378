import { styled } from '@mui/material/styles'

import LinkButton from 'src/components/LinkButton/LinkButton'

export const Link = styled(LinkButton)(({ theme }) => ({
  ...theme.typography['Lato Link 1'],

  [theme.breakpoints.down('sm')]: {
    ...theme.typography['Lato Link 2'],
  },
}))
