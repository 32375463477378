import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Beta = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: colors.grey[0],
  padding: theme.spacing(0.25, 0.5),
  backgroundColor: colors.orange[500],
  borderRadius: theme.spacing(0.5),
  ...theme.typography['Lato Overline Bold'],
  height: theme.spacing(2),
}))

Beta.defaultProps = { role: 'button' }
