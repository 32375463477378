import useDevice from './useDevice'

const usePromptInstallation = () => {
  const device = useDevice()

  const isIOS = device?.os?.name == 'iOS'
  const isAndroid = device?.os?.name == 'Android'
  const isMobile = device?.device?.type == 'mobile'

  return {
    isAppInstallible: isMobile && (isAndroid || isIOS),
    isAndroid,
    isIOS,
  }
}

export default usePromptInstallation
