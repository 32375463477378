import { QueryHookOptions, useQuery } from '@apollo/client'
import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { Nullable } from 'src/@types/Nullable'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useGraphQLErrorBoundary from 'src/hooks/useGraphQLErrorBoundary'

import { APPOINTMENT_BUNDLES_QUERY } from '../queries/appointmentBundles'
import { AppointmentBundlesVariables } from '../types/AppointmentBundlesVariables'
import { PageInfo } from '../types/PageInfo'

type AppointmentBundlesResponse = { appointmentBundles: Nullable<{ pageInfo: PageInfo; data: AppointmentBundle[] }> }

const useAppointmentBundles = (options: QueryHookOptions<AppointmentBundlesResponse, AppointmentBundlesVariables>) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<AppointmentBundlesResponse>(APPOINTMENT_BUNDLES_QUERY, {
    ...options,
    variables: { personReferenceId, ...options.variables },
    skip: options.skip || !personReferenceId,
  })

  useGraphQLErrorBoundary(rest.error)

  return { data: data?.appointmentBundles ?? undefined, ...rest }
}

export default useAppointmentBundles
