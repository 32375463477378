import { Nullable } from '@dentalux/common'

export enum ClinicComponentState {
  Good = 'GOOD',
  Bad = 'BAD',
  Disabled = 'DISABLED',
}

export type ClinicStatus = {
  referenceId: string
  clinicReferenceId: string
  state: ClinicComponentState
  pmsAdapter: Nullable<{
    state: ClinicComponentState
  }>
  xrayAdapter: Nullable<{
    state: ClinicComponentState
  }>
}
