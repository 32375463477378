import { useState, useEffect } from 'react'

interface Size {
  width: number
  height: number
}

const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] => {
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({ width: 0, height: 0 })

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return
        }

        const { width, height } = entries[0].contentRect

        setSize({ width, height })
      })
    })

    if (ref) {
      observer.observe(ref)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return [setRef, size]
}

export default useElementSize
