import { useQuery } from 'react-query'

import api from 'api'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

const usePatientScoring = () => {
  const personReferenceId = usePersonId()

  const query = useQuery(
    ['person_scores, ', personReferenceId],
    () => api.patientScorpio.getPersonScores({ personReferenceId }),
    {
      enabled: Boolean(personReferenceId),
    }
  )

  return query
}

export default usePatientScoring
