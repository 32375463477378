import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowIndicator = ({ width = 18, height = 16, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 18 16" width={width} height={height} sx={{ width, height }}>
      <path
        d="M10.7321 15C9.96225 16.3333 8.03775 16.3333 7.26795 15L0.339746 3C-0.430054 1.66667 0.532198 0 2.0718 0L15.9282 0C17.4678 0 18.4301 1.66667 17.6603 3L10.7321 15Z"
        fill={colors.grey[700]}
      />
    </SvgIcon>
  )
}

export default ArrowIndicator
