import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { BookingPurpose } from 'src/@types/Booking'
import { BookingIds } from 'src/components/providers/BookingsProvider'

const fromRest = (data: AppointmentBundle, purpose: BookingPurpose): BookingIds => ({
  clinicReferenceId: data.clinic.referenceId,
  practitionerReferenceId: data.mainPractitioner.referenceId,
  practitionerReferenceIds: [data.mainPractitioner.referenceId],
  appointmentBundleReferenceId: purpose === BookingPurpose.Reschedule ? data.referenceId : null,
  treatmentBundleReferenceId: purpose === BookingPurpose.Reschedule ? data.treatmentBundleReferenceId : null,
})

export const AppointmentIdsEntity = { fromRest }
