import { Box, svgIconClasses, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact?: boolean }>(({ compact }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: compact ? 'center' : 'unset',
}))

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact?: boolean }>(({ theme, compact }) => ({
  width: compact ? theme.spacing(9.25) : '100%',
  position: 'relative',
}))

export const Gradient = styled(Box)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(2.125),
  background: 'linear-gradient(270deg, #02A254 0%, #E58A00 50.02%, #C52B42 100%)',
  borderRadius: theme.spacing(4),
}))

export const Label = styled(Typography)({})

Label.defaultProps = { variant: 'Lato Caption Small Bold' }

export const AxisLabels = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const Indicator = styled(Box, {
  shouldForwardProp: (prop: string) => !['value', 'compact'].includes(prop),
})<{ value: number; compact: boolean }>(({ theme, compact, value }) => ({
  position: 'relative',
  height: compact ? 8 : 16,
  margin: theme.spacing(1, 0, compact ? 0.5 : 1, 0),
  width: `calc(100% - ${compact ? 2 : 4}px)`,

  [`.${svgIconClasses.root}`]: {
    position: 'absolute',
    width: compact ? 9 : 18,
    height: compact ? 8 : 16,
    left: `calc(${value}% - ${value >= (compact ? 9 : 18) / 2 ? (compact ? 9 : 18) / 2 : 0}px)`,
  },
}))
