import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useInsuranceQuotableStatus = ({ enabled }: { enabled: boolean }) => {
  const personReferenceId = usePersonId()

  return useQuery(
    ['insurance_status', personReferenceId],
    () => api.insurance.getQuotableStatus({ personReferenceId }),
    {
      enabled: Boolean(enabled && personReferenceId),
    }
  )
}
