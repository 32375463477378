import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const AddToHomeScreen = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
    <path
      d="M16.9854 23.1201C18.0904 23.1201 18.9854 22.2251 18.9854 21.1201V3.12011C18.9854 2.01511 18.0904 1.12012 16.9854 1.12012H6.98536C5.88035 1.12012 4.98535 2.01511 4.98535 3.12011V5.12012C4.98535 5.67212 5.43336 6.12011 5.98536 6.12011C7.00031 6 7.01525 4.87988 7.00031 4L12.0003 4.01562L17.0003 4V20H12.0003H7.00031C7.00031 18.5 6.53736 18.1201 5.98536 18.1201C5.43336 18.1201 4.98535 18.5682 4.98535 19.1202V21.1201C4.98535 22.2251 5.88035 23.1201 6.98536 23.1201H16.9854Z"
      fill={colors.grey[800]}
    />
    <path
      d="M10.1403 13.7739C10.2363 13.6781 10.2951 13.5449 10.2949 13.3985L10.2949 9.15631C10.2949 8.86306 10.0575 8.62512 9.76459 8.62484L5.52243 8.61524C5.22916 8.61457 4.99178 8.85142 4.99216 9.14431V9.20875C4.99216 9.502 5.22956 9.73994 5.52243 9.74023L8.41987 9.7468L3.51854 14.6371C3.31153 14.8436 3.31153 15.1796 3.51854 15.3871C3.72553 15.5945 4.06153 15.5953 4.26854 15.3888L9.16988 10.4985V13.3959C9.16989 13.6892 9.40728 13.9271 9.70015 13.9274L9.76461 13.9276C9.91124 13.9279 10.0444 13.8696 10.1403 13.7739Z"
      fill={colors.grey[800]}
    />
  </SvgIcon>
)

export default AddToHomeScreen
