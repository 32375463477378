import { useCallback } from 'react'

import { useRouter } from 'next/router'

import api from 'api'
import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { BookingPurpose } from 'src/@types/Booking'
import { ClinicComponentState } from 'src/@types/ClinicStatus'
import { DialogNames } from 'src/components/dialogs/DialogRoot'
import { ImmutableAppointmentKind } from 'src/components/dialogs/ImmutableAppointmentDialog/ImmutableAppointmentDialog'
import {
  setAppointmentBundle,
  setBookingIds,
  setBookingScreenType,
  setManipulatedAppointment,
  setTreatmentTitle,
  useBookingsDispatch,
} from 'src/components/providers/BookingsProvider'
import { useDialog } from 'src/components/providers/DialogProvider'
import isLessThanXHours from 'src/helpers/isLessThanXHours'
import { AppointmentIdsEntity } from 'src/screens/Appointments/Management/Management.helpers'
import { BookingScreenType } from 'src/screens/Appointments/Management/types'

import useDashboard from './useDashboard'
import useDateFnsConfig from './useDateFnsConfig'
import useIsDesktop from './useIsDesktop'
import { useStartBook } from './useStartBook'

type ManagementFields = {
  appointmentBundle: AppointmentBundle
}

const useManagement = () => {
  const router = useRouter()
  const isDesktop = useIsDesktop()
  const locale = useDateFnsConfig()

  const { refetch: refetchDashboard } = useDashboard({ onlyRefetch: true })

  const dispatch = useBookingsDispatch()

  const bookRedirect = '/appointments/book'

  const { openDialog } = useDialog()

  const initView = useCallback(
    ({ appointmentBundle }: ManagementFields) => {
      dispatch(setAppointmentBundle(appointmentBundle))
      dispatch(setBookingScreenType(BookingScreenType.DetailedView))
    },
    [dispatch]
  )

  const startBook = useStartBook()

  const checkIfAdapterIsAvailable = async (clinicReferenceId: string) => {
    return await api.monitoring.getClinicStatus({ clinicReferenceId }).then((res) => {
      return [ClinicComponentState.Disabled, ClinicComponentState.Good].includes(res.find((m) => m)?.pmsAdapter.state)
    })
  }

  const prepareAppointmentManipulation = (appointmentBundle: AppointmentBundle) => {
    dispatch(setAppointmentBundle(appointmentBundle))
    dispatch(setBookingScreenType(BookingScreenType.DetailedView))
  }

  const initAppointmentManipulation = (appointmentBundle: AppointmentBundle) => {
    dispatch(
      setManipulatedAppointment({
        start: appointmentBundle.start,
        practitioner: appointmentBundle.mainPractitioner.name,
      })
    )
  }

  const isImmutable = async (appointmentBundle: AppointmentBundle) =>
    !(await checkIfAdapterIsAvailable(appointmentBundle.clinic.referenceId)) ||
    !appointmentBundle.mainPractitioner ||
    !appointmentBundle.canBeCancelled

  const isShortNotice = (appointmentBundle: AppointmentBundle) =>
    isLessThanXHours(appointmentBundle.start, 4) || !appointmentBundle.bookableByPatient

  const initBook = startBook
    ? ({ appointmentBundle }: ManagementFields) => {
        const practitionerReferenceId = appointmentBundle.mainPractitioner.referenceId

        dispatch(setBookingIds(AppointmentIdsEntity.fromRest(appointmentBundle, BookingPurpose.Booking)))

        if (isDesktop) dispatch(setBookingScreenType(BookingScreenType.Treatment))
        else router.push({ pathname: '/appointments/treatment', query: { practitionerReferenceId } })
      }
    : undefined

  const initReschedule = useCallback(
    async ({ appointmentBundle }: ManagementFields) => {
      const props = { phoneNumber: appointmentBundle.clinic.phoneNumber }

      prepareAppointmentManipulation(appointmentBundle)

      if (await isImmutable(appointmentBundle)) {
        return openDialog(DialogNames.ImmutableAppointment, { kind: ImmutableAppointmentKind.Reschedule, ...props })
      }

      if (isShortNotice(appointmentBundle)) {
        return openDialog(DialogNames.ShortTermReschedule, props)
      }

      initAppointmentManipulation(appointmentBundle)

      dispatch(setTreatmentTitle(appointmentBundle.treatmentTitle))
      dispatch(setBookingIds(AppointmentIdsEntity.fromRest(appointmentBundle, BookingPurpose.Reschedule)))

      if (isDesktop) dispatch(setBookingScreenType(BookingScreenType.Book))
      else router.push(bookRedirect)
    },
    [isDesktop, router, dispatch, openDialog]
  )

  const initCancel = useCallback(
    async ({ appointmentBundle }: ManagementFields) => {
      const props = { phoneNumber: appointmentBundle.clinic.phoneNumber }

      prepareAppointmentManipulation(appointmentBundle)

      if (await isImmutable(appointmentBundle)) {
        return openDialog(DialogNames.ImmutableAppointment, { kind: ImmutableAppointmentKind.Canceling, ...props })
      }

      if (isShortNotice(appointmentBundle)) {
        return openDialog(DialogNames.ShortTermCancelation, props)
      }

      initAppointmentManipulation(appointmentBundle)

      openDialog(DialogNames.CancelAppointment, {
        referenceId: appointmentBundle.referenceId,
        feeWarning: isLessThanXHours(appointmentBundle.start),
        onSuccess: () => {
          refetchDashboard()
          openDialog(DialogNames.CancellationConfirmation)
          router.push('/appointments/cancelled')
        },
      })
    },
    [locale, dispatch, openDialog]
  )

  return { initView, initBook, initCancel, initReschedule }
}

export default useManagement
