import React from 'react'

import { useTranslation } from 'next-i18next'

import { Chip } from '@dentalux/ui-library-core/cjs/components/chip'

import { RecommendationData, RecommendationSpecialityAggregation } from 'src/@types/Recommendation'
import { DialogNames } from 'src/components/dialogs/DialogRoot'
import RecommendationTick from 'src/components/icons/RecommendationTick'
import { useDialog } from 'src/components/providers/DialogProvider'
import {
  setActive,
  useRecommendation,
  useRecommendationDispatch,
} from 'src/components/providers/RecommendationProvider'
import { SwipeStack } from 'src/components/SwipeStack/Book'
import { DataTestIds } from 'src/config/dataTestIds'
import useElementSize from 'src/hooks/useElementSize'
import useIsMobile from 'src/hooks/useIsMobile'

import { Recommendation } from '../Recommendation/Recommendation'
import Section from '../Section'

import * as S from './RecommendationSection.styles'

export type RecommendationSectionProps = {
  processing: boolean
  recommendations: {
    dental: RecommendationData[]
    other: RecommendationData[]
  }
}

const dataTestIdMapping = {
  [RecommendationSpecialityAggregation.Dental]: DataTestIds.DentalMedicineRecommendationsButton,
  [RecommendationSpecialityAggregation.Other]: DataTestIds.HumanMedicineRecommendationsButton,
}

export const RecommendationSection = ({ processing, recommendations }: RecommendationSectionProps) => {
  const { t } = useTranslation()

  const [cardRef, { width }] = useElementSize()

  const isMobile = useIsMobile()

  const { active, entries } = useRecommendation({ recommendations })

  const dispatch = useRecommendationDispatch()

  const prepareChip = (speciality: RecommendationSpecialityAggregation) => ({
    icon: active === speciality ? <RecommendationTick /> : undefined,
    variant: (speciality === active ? 'filled' : 'bordered') as 'filled' | 'bordered',
    onClick: () => dispatch(setActive(speciality)),
    disabled: !recommendations[speciality === RecommendationSpecialityAggregation.Dental ? 'dental' : 'other'].length,
    ['data-testid']: dataTestIdMapping[speciality],
  })

  const { openDialog } = useDialog()

  const getCount = (count: number) => {
    if (count > 1) {
      return ` (${count})`
    }

    return ''
  }

  return (
    <Section
      title={t('b2c.home.subheading.recommendation')}
      show={!processing && (recommendations.dental.length > 0 || recommendations.other.length > 0)}
    >
      <S.Chips>
        <Chip
          color="blue"
          label={`${t('b2c.recommendations.kind.dental')}${getCount(recommendations.dental.length)}`}
          {...prepareChip(RecommendationSpecialityAggregation.Dental)}
        />
        <Chip
          color="purple"
          label={`${t('b2c.recommendations.kind.human')}${getCount(recommendations.other.length)}`}
          {...prepareChip(RecommendationSpecialityAggregation.Other)}
        />
      </S.Chips>

      <SwipeStack direction={isMobile ? 'row' : 'column'} ref={cardRef}>
        {entries.map((recommendation) => (
          <Recommendation
            onClose={(e) => {
              e.stopPropagation()
              openDialog(DialogNames.IgnoreRecommendation, { referenceId: recommendation.referenceId })
            }}
            key={recommendation.referenceId}
            data={recommendation}
            width={width}
          />
        ))}
      </SwipeStack>
    </Section>
  )
}
