import { Box } from '@mui/material'
import { CSSObject, styled } from '@mui/material/styles'

import { CrossButton } from 'src/components/CrossButton/CrossButton'

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
}))

export const Wrapper = styled(Box)({
  justifyContent: 'space-between',
})

Wrapper.defaultProps = {
  display: 'flex',
  gap: 2,
}

export const Header = styled(Box)<{ headerStyles: CSSObject }>(({ theme, headerStyles }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...headerStyles,
}))

export const CloseButton = styled(CrossButton)({
  padding: 0,
})
