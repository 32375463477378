import { RecommendationSpecialityAggregation } from 'src/@types/Recommendation'
import useAppointmentBundles from 'src/graphql/hooks/useAppointmentBundles'
import useClinics from 'src/graphql/hooks/useClinics'
import usePerson from 'src/graphql/hooks/usePerson'
import { AppointmentBundleKind } from 'src/graphql/types/AppointmentBundlesVariables'

import { useInsuranceQuotableStatus } from './api/useInsuranceQuotableStatus'
import { usePersonRecommendation } from './api/usePersonRecommendation'
import { useSupplementaryInsuranceDismissal } from './api/useSupplementaryInsuranceDismissal'

const useDashboard = ({ onlyRefetch }: { onlyRefetch: boolean }) => {
  const { data: person, loading: personLoading } = usePerson({
    skip: onlyRefetch,
  })

  const { refetch: refetchClinics } = useClinics({
    skip: onlyRefetch,
  })

  const {
    data: appointmentBundles,
    loading: appointmentBundlesLoading,
    refetch: refetchAppointmentBundles,
  } = useAppointmentBundles({
    variables: { page: 0, size: 5, kind: AppointmentBundleKind.Upcoming },
    skip: onlyRefetch,
  })

  const dismissed = useSupplementaryInsuranceDismissal()

  const { data: quotableStatus } = useInsuranceQuotableStatus({
    enabled: !onlyRefetch && !dismissed,
  })

  const {
    data: dentalRecommendations,
    isLoading: dentalRecommendationsLoading,
    refetch: refetchDentalRecommendations,
  } = usePersonRecommendation({
    perPage: 1000,
    speciality: RecommendationSpecialityAggregation.Dental,
  })

  const {
    data: otherRecommendations,
    isLoading: otherRecommendationsLoading,
    refetch: refetchOtherRecommendations,
  } = usePersonRecommendation({
    perPage: 1000,
    speciality: RecommendationSpecialityAggregation.Other,
  })

  const refetch = () => {
    refetchClinics()
    refetchAppointmentBundles()
    refetchDentalRecommendations()
    refetchOtherRecommendations()
  }

  return {
    person,
    personSummary: person?.summary ?? {},
    appointments: appointmentBundles?.data ?? [],
    recommendations: {
      dental: dentalRecommendations?.content ?? [],
      other: otherRecommendations?.content ?? [],
    },
    supplementaryInsurance: {
      dismissed,
      quotable: quotableStatus,
    },
    isLoading: {
      appointmentBundlesLoading,
      personLoading,
      otherRecommendationsLoading,
      dentalRecommendationsLoading,
    },
    refetch,
  }
}

export default useDashboard
