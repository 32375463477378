import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)({})

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  a: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
}))

export const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3),
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
}))
