import React, { ReactElement, ReactNode, useEffect } from 'react'

import { useRouter } from 'next/router'

import * as S from './DashboardWarning.styles'

export const DashboardWarning = ({
  hidden,
  title,
  redirect,
  onClose,
  onClick,
  children,
}: {
  onClose: () => void
  onClick?: (e) => void
  hidden: boolean
  title?: ReactElement | ReactElement[]
  redirect?: {
    label: string
    to?: string
  }
  children?: ReactNode | ReactNode[]
}) => {
  const { push, prefetch } = useRouter()

  useEffect(() => {
    redirect && prefetch(redirect.to)
  }, [])

  if (hidden) return null

  return (
    <S.Root oneLine={!children} onClose={onClose}>
      <S.WarningTitle>{title}</S.WarningTitle>
      <S.WarningMessage>
        {children}
        {redirect && (
          <S.Link
            onClick={(e) => {
              if (onClick) {
                return onClick(e)
              }

              push(redirect?.to)
            }}
          >
            {redirect?.label.split(' ').join('\xa0')}
          </S.Link>
        )}
      </S.WarningMessage>
    </S.Root>
  )
}
