import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box, Button as BaseButton, svgIconClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

import { AppointmentType } from 'src/@types/Appointment'

import Arrow from '../icons/Arrow'

const getAppointmentColor = (selected: boolean, type: AppointmentType) => {
  if (type === AppointmentType.Canceled) return colors.error[selected ? 900 : 300]
  if (type === AppointmentType.Upcoming) return colors.aqua[selected ? 800 : 600]

  return colors.grey[selected ? 400 : 300]
}

export const Root = styled(Box, {
  shouldForwardProp: (prop: string) => !['selected'].includes(prop),
})<{ selected: boolean; type: AppointmentType }>(({ theme, selected, type }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[selected ? 7 : 3],
  height: 'fit-content',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
  width: '100%',

  ...(selected && {
    border: `${theme.spacing(0.25)} solid ${getAppointmentColor(selected, type)}`,

    '& > div:first-child': {
      borderRadius: `${theme.spacing(0.5, 0.5, 0, 0)}`,
    },
  }),
}))

export const Header = styled(Box, {
  shouldForwardProp: (prop: string) => !['selected'].includes(prop),
})<{ type: AppointmentType; selected: boolean }>(({ theme, type, selected }) => {
  const fill = getAppointmentColor(selected, type)

  return {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    color: type === AppointmentType.Past ? colors.grey[selected ? 800 : 700] : colors.grey[0],
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    backgroundColor: fill,
    fontSize: '16px',
    margin: selected ? '-1px -1px 0 -1px' : '0',

    '> svg path': { fill },
  }
})

export const Meta = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...theme.typography['Lato Emphasized 1'],

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
  },
}))

export const MetaExtra = styled(Meta)(({ theme }) => ({
  alignItems: 'center',

  [`.${svgIconClasses.root}`]: {
    marginRight: theme.spacing(1),
  },
}))

export const ArrowIcon = styled(Arrow, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
  opacity: visible ? 1 : 0,
  transform: 'translateY(-50%)',
  transition: '.1s ease-out',
  position: 'absolute',
  right: visible ? -10 : 0,
  top: '50%',
}))

export const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
}))

export const Actions = styled(Box)(({ theme }) => ({
  '&:has(div)': {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  width: '100%',

  '&:not(:last-child) ': {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}))

export const Button = styled(BaseButton)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  width: '100%',

  '&:not(:last-child) ': {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}))

Button.defaultProps = { fullWidth: true, variant: 'borderless', size: 'md' }

export const Title = styled('span')({})
