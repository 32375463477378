import React from 'react'

import { Trans, useTranslation } from 'next-i18next'

import IosAddIcon from 'src/components/icons/IosAdd'
import IosShareIcon from 'src/components/icons/IosShare'
import MoreVertIcon from 'src/components/icons/MoreVert'
import SwipeableDrawer from 'src/components/SwipeableDrawer'
import usePromptInstallation from 'src/hooks/usePromptInstallation'

import {
  setBeforeInstallPromptDismiss,
  setIsBeforeInstallPromptOpen,
  useAuthorizedContext,
  useAuthorizedDispatch,
} from '../providers/AuthorizedProvider'

import * as S from './Shortcut.styles'

export const Shortcut = () => {
  const { t } = useTranslation()
  const dispatch = useAuthorizedDispatch()

  const { isAndroid, isIOS } = usePromptInstallation()

  const { isBeforeInstallPromptOpened } = useAuthorizedContext()

  const handleDismiss = () => {
    dispatch(setIsBeforeInstallPromptOpen(false))
    dispatch(setBeforeInstallPromptDismiss(true))
  }

  const ShareIcon = isAndroid ? MoreVertIcon : IosShareIcon
  const AddAndroidIcon = isAndroid ? S.HomeScreenIcon : React.Fragment
  const AddIosIcon = isIOS ? IosAddIcon : React.Fragment

  return (
    <SwipeableDrawer
      open={isBeforeInstallPromptOpened}
      closeDrawer={() => dispatch(setIsBeforeInstallPromptOpen(false))}
    >
      <S.Title>{t('b2c.shortcut.title')}</S.Title>
      <S.Description>{t('b2c.shortcut.description')}</S.Description>

      <S.List>
        <S.Item bold>
          <Trans i18nKey="b2c.shortcut.step1" components={[<ShareIcon key={1} />]} />
        </S.Item>
        <S.Item>{t('b2c.shortcut.step2')}</S.Item>
        <S.Item bold>
          <Trans i18nKey="b2c.shortcut.step3" components={[<AddAndroidIcon key={1} />, <AddIosIcon key={2} />]} />
        </S.Item>
      </S.List>

      <S.DissmisButton onClick={handleDismiss}>{t('b2c.shortcut.dismiss')}</S.DissmisButton>
    </SwipeableDrawer>
  )
}
