import React from 'react'

import { useTranslation } from 'next-i18next'

import {
  setWarningDismiss,
  useAuthorizedContext,
  useAuthorizedDispatch,
  WarningDismissKind,
} from 'src/components/providers/AuthorizedProvider'
import { useCostPlanEvaluation } from 'src/hooks/useCostplanEvaluation'

import { DashboardWarning } from '../DashboardWarning/DashboardWarning'

export const CostPlansWarning = () => {
  const { t } = useTranslation()
  const dispatch = useAuthorizedDispatch()

  const { costPlanWarningDismissed } = useAuthorizedContext()

  const { costPlanRecommendation } = useCostPlanEvaluation({
    enabled: !costPlanWarningDismissed,
  })

  return (
    <DashboardWarning
      onClose={() => dispatch(setWarningDismiss({ kind: WarningDismissKind.CostPlan }))}
      hidden={costPlanWarningDismissed || !costPlanRecommendation}
      redirect={{ to: '/costplan', label: t('b2c.costplan.dashboardAlertRedirect') }}
      title={t('b2c.costplan.dashboardAlertTitle')}
    />
  )
}
