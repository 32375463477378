import { Box, Divider as BaseDivider } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Card = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  background: theme.palette.grey[0],
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
}))

export const Conditions = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
}))

export const Item = styled(Box)({})

Item.defaultProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const Divider = styled(BaseDivider)(({ theme }) => ({
  height: theme.spacing(4),
}))

Divider.defaultProps = { orientation: 'vertical' }

export const Frame = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}))
