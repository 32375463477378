import React from 'react'

import { useTranslation } from 'next-i18next'

import { PersonSummary } from 'src/@types/Person'
import DentalFindings from 'src/components/icons/DentalFindings'
import Periodontium from 'src/components/icons/Periodontium'
import VisitationHistory from 'src/components/icons/VisitationHistory'
import XRays from 'src/components/icons/XRays'

const usePersonSummary = (personSummary: Partial<PersonSummary>) => {
  const { t } = useTranslation()

  return [
    {
      Icon: <DentalFindings />,
      title: t('b2c.home.dentalFindings.heading'),
      text: t('b2c.home.dentalFindings.text'),
      active: !!personSummary?.hasJawFindings,
      link: '/record',
    },
    {
      Icon: <VisitationHistory />,
      title: t('b2c.home.visitationDetails.heading'),
      text: t('b2c.home.visitationDetails.text'),
      active: !!personSummary?.hasPatientHistory,
      link: '/record/timeline',
    },
    {
      Icon: <XRays />,
      title: t('b2c.home.xRayImaging.heading'),
      text: t('b2c.home.xRayImaging.text'),
      active: !!personSummary?.hasImages,
      link: '/record/imaging',
    },
    {
      Icon: <Periodontium />,
      title: t('b2c.home.periodontium.heading'),
      text: t('b2c.home.periodontium.text'),
      active: !!personSummary?.hasPsiDiagnosis,
      link: '/record/gums',
    },
  ]
}

export default usePersonSummary
