import { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { RecommendationData } from 'src/@types/Recommendation'
import {
  setBookingIds,
  setDefaultBookingContext,
  setRecommendation,
  setRecommendationDefaultBookingContext,
  setTreatmentTitle,
  useBookingsDispatch,
} from 'src/components/providers/BookingsProvider'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useClinics from 'src/graphql/hooks/useClinics'

import usePractitionerSelection from './usePractitionerSelection'

export const useBookRecommendation = ({ data }: { data: RecommendationData }) => {
  const { t } = useTranslation()

  const dispatch = useBookingsDispatch()

  const { initPractitionerSelection } = usePractitionerSelection()

  const router = useRouter()

  const personReferenceId = usePersonId()

  const { data: clinics } = useClinics({
    skip: !personReferenceId,
  })

  const appointmentsPractitionerRedirect = '/appointments/practitioner'
  const appointmentsRecommendationRedirect = '/appointments/recommendation'

  useEffect(() => {
    router.prefetch(appointmentsPractitionerRedirect)
    router.prefetch(appointmentsRecommendationRedirect)
  }, [router.prefetch])

  return (e) => {
    e.stopPropagation()

    dispatch(setDefaultBookingContext())

    const treatments = data.treatmentBundles.map(({ referenceId, name, translationKey, clinicReferenceId }) => ({
      referenceId,
      name: t(translationKey ?? name),
      clinicReferenceId,
    }))

    dispatch(setRecommendation({ treatments, referenceId: data.referenceId }))

    const options = clinics?.data.filter((clinic) =>
      treatments.find((rec) => rec.clinicReferenceId === clinic.clinic.referenceId && clinic.isClinicActive)
    )

    if (options?.length === 1) {
      const [
        {
          clinic: { referenceId },
        },
      ] = options

      const treatment = treatments?.find((rec) => rec.clinicReferenceId === referenceId)
      const treatmentBundleReferenceId = treatment?.referenceId ?? null
      const clinicReferenceId = referenceId

      dispatch(setRecommendationDefaultBookingContext())

      dispatch(setBookingIds({ clinicReferenceId, treatmentBundleReferenceId }))

      dispatch(setTreatmentTitle(treatment?.name ?? ''))

      initPractitionerSelection({
        treatmentBundleReferenceId,
        clinicReferenceId,
      })
    } else {
      router.push(appointmentsRecommendationRedirect)
    }
  }
}
