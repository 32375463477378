import React from 'react'

import { IconButton as BaseIconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import CloseIcon from 'src/components/icons/Close'

export const Root = styled(BaseIconButton)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

Root.defaultProps = {
  children: <CloseIcon />,
}
