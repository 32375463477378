import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Stack)(({ theme }) => ({
  overflowX: 'auto',
  margin: theme.spacing(-1, -2),
  padding: theme.spacing(1, 2),

  [theme.breakpoints.up('sm')]: {
    overflowX: 'unset',
    padding: 0,
    margin: 0,
  },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

Root.defaultProps = { direction: 'row', gap: 2 }
