export enum AppointmentBundleKind {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
  Canceled = 'CANCELED',
  Any = 'ANY',
}

export type AppointmentBundlesVariables = {
  page: number
  size: number
  kind?: AppointmentBundleKind
}

export type AppointmentBundleVariables = {
  appointmentBundleId: string
  personReferenceId: string
}
