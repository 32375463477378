import { useLocalStorage } from '@dentalux/common'

import { parseISO, isAfter, addMonths } from 'date-fns'

const DISMISSAL_VALIDITY_LENGTH_MONTHS = 6
export const SUPPLEMENTARY_INSURANCE_DISMISSAL_AT_KEY = 'supplementaryInsuranceDismissedAt'

export const useSupplementaryInsuranceDismissal = () => {
  const [value] = useLocalStorage<string>(SUPPLEMENTARY_INSURANCE_DISMISSAL_AT_KEY)

  return value && isAfter(addMonths(parseISO(value), DISMISSAL_VALIDITY_LENGTH_MONTHS), new Date())
}
