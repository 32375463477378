import { Alert, Typography, alertClasses, buttonClasses, svgIconClasses, buttonBaseClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Alert, {
  shouldForwardProp: (name) => name !== 'oneLine',
})<{ oneLine: boolean }>(({ theme, oneLine }) => ({
  padding: theme.spacing(1),

  [`& .${alertClasses.action}`]: {
    alignSelf: 'flex-start',
    padding: oneLine ? theme.spacing(0) : theme.spacing(0, 0.5, 1, 1),

    [`& .${buttonClasses.root}`]: {
      width: 24,
      height: 24,
    },
  },

  [`& .${alertClasses.icon}`]: {
    alignSelf: oneLine ? 'center!important' : 'flex-start',
  },

  [`& .${svgIconClasses.root}`]: {
    width: 24,
    height: 24,
  },

  [`& .${buttonBaseClasses.root}`]: {
    margin: oneLine ? theme.spacing(0, 1, 0, 1) : theme.spacing(1),
    padding: theme.spacing(0.25),
  },
}))

Root.defaultProps = {
  severity: 'warning',
}

export const WarningTitle = styled(Typography)()

WarningTitle.defaultProps = {
  variant: 'Lato Caption Bold',
}

export const WarningMessage = styled(Typography)({})

WarningMessage.defaultProps = {
  variant: 'Lato Caption',
}

export const Link = styled('a')(({ theme }) => ({
  ...theme.typography['Lato Caption Bold'],
  lineHeight: theme.spacing(3),
  textDecoration: 'underline',
  display: 'inline',
  cursor: 'pointer',
  wordBreak: 'keep-all',
}))
