import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Arrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 9 20" sx={{ width: 9, height: 20 }}>
      <path
        d="M9 9.85578C9 7 3 5.45652 9.53674e-07 0.5L5.4472e-07 9.85578L1.23158e-07 19.5C3 14.5435 9 12.5 9 9.85578Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default Arrow
