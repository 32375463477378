import React from 'react'

import ArrowIndicator from '../icons/ArrowIndicator'

import * as S from './ConditionGradient.styles'

type ConditionGradientProps = {
  value?: number
  compact?: boolean
  label?: string
  axisLabels?: string[]
}

const ConditionGradient = ({ value, label, axisLabels = [], compact = false }: ConditionGradientProps) => {
  const renderLabel = (label) => <S.Label key={label}>{label}</S.Label>

  return (
    <S.Root compact={compact}>
      <S.Label>{label}</S.Label>

      <S.Container compact={compact}>
        <S.Indicator value={value} compact={compact}>
          <ArrowIndicator />
        </S.Indicator>

        <S.Gradient />

        <S.AxisLabels>{axisLabels.map(renderLabel)}</S.AxisLabels>
      </S.Container>
    </S.Root>
  )
}

export default ConditionGradient
