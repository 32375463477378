import React from 'react'

import { useTranslation } from 'next-i18next'

import {
  setWarningDismiss,
  useAuthorizedContext,
  useAuthorizedDispatch,
  WarningDismissKind,
} from 'src/components/providers/AuthorizedProvider'
import { useInvoiceEvaluation } from 'src/hooks/useInvoiceEvaluation'

import { DashboardWarning } from '../DashboardWarning/DashboardWarning'

export const InvoicesWarning = () => {
  const { t } = useTranslation()
  const dispatch = useAuthorizedDispatch()

  const redirect = `/invoice`

  const { invoicesWarningDismissed } = useAuthorizedContext()

  const { open, outdated } = useInvoiceEvaluation({ enabled: !invoicesWarningDismissed })

  return (
    <DashboardWarning
      onClose={() => dispatch(setWarningDismiss({ kind: WarningDismissKind.Invoice }))}
      hidden={invoicesWarningDismissed || open.length + outdated.length === 0}
      redirect={{ to: redirect, label: t('b2c.invoices.dashboardAlertRedirect') }}
      title={t('b2c.invoices.dashboardAlertTitle', { count: open.length + outdated.length })}
    />
  )
}
