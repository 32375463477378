import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const IosAdd = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
    <path
      d="M6 3C4.35503 3 3 4.35503 3 6V18C3 19.645 4.35503 21 6 21H18C19.645 21 21 19.645 21 18V6C21 4.35503 19.645 3 18 3H6ZM6 5H18C18.565 5 19 5.43497 19 6V18C19 18.565 18.565 19 18 19H6C5.43497 19 5 18.565 5 18V6C5 5.43497 5.43497 5 6 5ZM11.9844 6.98633C11.7195 6.99047 11.4671 7.09953 11.2825 7.28957C11.098 7.47962 10.9964 7.73512 11 8V11H8C7.86749 10.9981 7.73593 11.0226 7.61296 11.072C7.48999 11.1214 7.37807 11.1948 7.2837 11.2878C7.18933 11.3809 7.11439 11.4918 7.06324 11.614C7.0121 11.7363 6.98576 11.8675 6.98576 12C6.98576 12.1325 7.0121 12.2637 7.06324 12.386C7.11439 12.5082 7.18933 12.6191 7.2837 12.7122C7.37807 12.8052 7.48999 12.8786 7.61296 12.928C7.73593 12.9774 7.86749 13.0019 8 13H11V16C10.9981 16.1325 11.0226 16.2641 11.072 16.387C11.1214 16.51 11.1948 16.6219 11.2878 16.7163C11.3809 16.8107 11.4918 16.8856 11.614 16.9368C11.7363 16.9879 11.8675 17.0142 12 17.0142C12.1325 17.0142 12.2637 16.9879 12.386 16.9368C12.5082 16.8856 12.6191 16.8107 12.7122 16.7163C12.8052 16.6219 12.8786 16.51 12.928 16.387C12.9774 16.2641 13.0019 16.1325 13 16V13H16C16.1325 13.0019 16.2641 12.9774 16.387 12.928C16.51 12.8786 16.6219 12.8052 16.7163 12.7122C16.8107 12.6191 16.8856 12.5082 16.9368 12.386C16.9879 12.2637 17.0142 12.1325 17.0142 12C17.0142 11.8675 16.9879 11.7363 16.9368 11.614C16.8856 11.4918 16.8107 11.3809 16.7163 11.2878C16.6219 11.1948 16.51 11.1214 16.387 11.072C16.2641 11.0226 16.1325 10.9981 16 11H13V8C13.0018 7.86621 12.9768 7.73341 12.9264 7.60947C12.876 7.48553 12.8012 7.37297 12.7065 7.27845C12.6118 7.18393 12.4991 7.10937 12.375 7.05919C12.251 7.00902 12.1182 6.98424 11.9844 6.98633Z"
      fill={colors.grey[800]}
    />
  </SvgIcon>
)

export default IosAdd
