import { useQuery } from '@apollo/client'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useGraphQLErrorBoundary from 'src/hooks/useGraphQLErrorBoundary'

import { PRACTITIONERS_AVAILABILITIES_QUERY } from '../queries/practitionersAvailabilities'
import {
  PractitionersAvailabilitiesVariables,
  PractitionersAvailabilitiesResponse,
} from '../types/PractitionersAvailabilities'

const usePractitionersAvailabilities = ({
  clinicReferenceId,
  treatmentBundleReferenceId,
  onCompleted,
}: {
  clinicReferenceId?: string
  treatmentBundleReferenceId?: string
  onCompleted?: (data: PractitionersAvailabilitiesResponse) => void
}) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<PractitionersAvailabilitiesResponse, PractitionersAvailabilitiesVariables>(
    PRACTITIONERS_AVAILABILITIES_QUERY,
    {
      variables: {
        clinicReferenceId,
        treatmentBundleReferenceId,
        personReferenceId,
      },
      skip: !clinicReferenceId || !treatmentBundleReferenceId || !personReferenceId,
      onCompleted,
    }
  )

  useGraphQLErrorBoundary(rest.error)

  return { data: data?.practitionersAvailabilities || [], ...rest }
}

export default usePractitionersAvailabilities
