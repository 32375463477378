import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const XRays = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32" sx={{ width: 32, height: 32 }}>
    <path
      d="M13.2711 20.7156C13.289 21.3579 13.12 21.9916 12.7845 22.5395V23.512C12.7845 23.6115 12.745 23.7069 12.6746 23.7772C12.6043 23.8475 12.5089 23.887 12.4095 23.887C12.31 23.887 12.2146 23.8475 12.1443 23.7772C12.074 23.7069 12.0345 23.6115 12.0345 23.512V22.4298C12.0344 22.3557 12.0564 22.2833 12.0975 22.2218C12.3399 21.8559 12.4844 21.4339 12.5171 20.9962C12.5498 20.5584 12.4697 20.1197 12.2843 19.7218C12.1221 19.3846 12.0367 19.0156 12.0343 18.6415C12.0343 18.6342 12.0343 18.627 12.0349 18.6198L12.0639 18.1191C12.0692 18.0274 12.1081 17.9407 12.1731 17.8757L14.4691 15.5798C14.7028 15.3454 14.8341 15.028 14.8341 14.697C14.8341 14.366 14.7028 14.0485 14.4691 13.8142L14.3225 13.6677L9.38584 17.5766C9.34725 17.6074 9.30296 17.6303 9.25552 17.6439C9.20808 17.6576 9.15841 17.6617 9.10937 17.6561C9.06033 17.6505 9.01288 17.6352 8.96974 17.6112C8.9266 17.5873 8.88862 17.555 8.85797 17.5163C8.82733 17.4776 8.80463 17.4332 8.79118 17.3857C8.77772 17.3382 8.77378 17.2885 8.77956 17.2395C8.78535 17.1905 8.80076 17.1431 8.82491 17.1001C8.84905 17.057 8.88146 17.0191 8.92027 16.9886L12.2782 14.3298V10.3013L7.00484 14.5006C6.68447 14.8332 6.50598 15.2773 6.50709 15.739V25.7897H14.199V24.5943C14.199 24.4949 14.2385 24.3995 14.3088 24.3292C14.3791 24.2588 14.4745 24.2193 14.574 24.2193C15.0484 24.2188 15.5033 24.0301 15.8387 23.6946C16.1742 23.3591 16.3629 22.9042 16.3635 22.4298V20.8437H13.9033C13.6861 20.8439 13.4711 20.8004 13.2711 20.7156Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M15.3562 26.5398H4.97168C4.83361 26.5398 4.72168 26.6517 4.72168 26.7898V30.0313C4.72168 30.1694 4.83361 30.2813 4.97168 30.2813H15.3562C15.4943 30.2813 15.6062 30.1694 15.6062 30.0313V26.7898C15.6062 26.6517 15.4943 26.5398 15.3562 26.5398Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M26.4033 1.71875H13.9033C13.6713 1.719 13.4489 1.81127 13.2849 1.97531C13.1208 2.13935 13.0286 2.36176 13.0283 2.59375V13.7362L14.1186 12.8728C14.1297 12.8646 14.1412 12.8572 14.1531 12.8504V3.40625C14.1531 3.30679 14.1926 3.21141 14.2629 3.14108C14.3332 3.07076 14.4286 3.03125 14.5281 3.03125H25.7781C25.8775 3.03125 25.9729 3.07076 26.0432 3.14108C26.1136 3.21141 26.1531 3.30679 26.1531 3.40625V17.1562C26.1531 17.2557 26.1136 17.3511 26.0432 17.4214C25.9729 17.4917 25.8775 17.5312 25.7781 17.5312H14.5281C14.4286 17.5312 14.3332 17.4917 14.2629 17.4214C14.1926 17.3511 14.1531 17.2557 14.1531 17.1562V16.9566L13.0281 18.0816V19.2188C13.0283 19.4507 13.1206 19.6732 13.2846 19.8372C13.4487 20.0012 13.6711 20.0935 13.9031 20.0938H26.4031C26.6351 20.0935 26.8575 20.0012 27.0215 19.8372C27.1856 19.6732 27.2778 19.4507 27.2781 19.2188V2.59375C27.2778 2.3618 27.1856 2.13943 27.0216 1.97539C26.8576 1.81136 26.6353 1.71906 26.4033 1.71875ZM20.8237 19.0312H19.483C19.3836 19.0312 19.2882 18.9917 19.2179 18.9214C19.1475 18.8511 19.108 18.7557 19.108 18.6562C19.108 18.5568 19.1475 18.4614 19.2179 18.3911C19.2882 18.3208 19.3836 18.2812 19.483 18.2812H20.8237C20.9232 18.2812 21.0185 18.3208 21.0889 18.3911C21.1592 18.4614 21.1987 18.5568 21.1987 18.6562C21.1987 18.7557 21.1592 18.8511 21.0889 18.9214C21.0185 18.9917 20.9232 19.0312 20.8237 19.0312Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M14.9033 16.2066V16.7812H25.4033V3.78125H14.9033V13.1881L14.9995 13.2843C15.3737 13.6594 15.5839 14.1676 15.5839 14.6974C15.5839 15.2272 15.3737 15.7354 14.9995 16.1104L14.9033 16.2066ZM16.7543 8.58925C17.0931 7.52925 18.0494 7.26263 18.8318 7.36606C19.286 7.42631 19.7864 7.71531 20.1176 7.90656L20.1597 7.93087C20.2706 7.87693 20.3781 7.8164 20.4818 7.74963C20.7196 7.59106 20.9818 7.47267 21.2581 7.39919C21.8089 7.24919 22.2956 7.27725 22.7032 7.48306C23.0717 7.67449 23.3558 7.9961 23.5004 8.38538C23.8161 9.18275 23.6386 10.3523 22.8789 10.7554C22.7244 10.8374 22.4028 11.0081 22.35 11.0934C22.3257 11.1327 22.3398 11.3016 22.3549 11.4803C22.3591 11.5298 22.3635 11.5829 22.3679 11.6399C22.4008 12.0712 22.4491 13.5387 21.6843 14.3289C21.6162 14.3957 21.5331 14.4453 21.442 14.4735C21.3428 14.509 21.236 14.518 21.1323 14.4997C21.0285 14.4814 20.9313 14.4365 20.8501 14.3693C20.7689 14.3021 20.7067 14.2149 20.6694 14.1164C20.6321 14.0178 20.6211 13.9113 20.6374 13.8072C20.6499 13.6706 20.7671 12.173 20.1608 11.4072C19.5438 12.1671 19.6623 13.6702 19.6746 13.8072C19.6898 13.911 19.6781 14.0171 19.6405 14.1151C19.6028 14.2131 19.5406 14.2998 19.4598 14.3667C19.3789 14.4337 19.2822 14.4787 19.1789 14.4974C19.0756 14.5161 18.9692 14.5079 18.87 14.4736C18.7787 14.4453 18.6956 14.3956 18.6274 14.3286C17.8629 13.5387 17.911 12.071 17.9439 11.6399C17.9484 11.5826 17.9528 11.5293 17.957 11.4794C17.9721 11.3008 17.9862 11.1321 17.9619 11.0929C17.9091 11.0075 17.5877 10.8374 17.4333 10.7554C16.7755 10.4075 16.4773 9.45587 16.7543 8.58925Z"
      fill={colors.aqua[800]}
    />
    <path
      d="M17.7843 10.0929C18.6515 10.552 18.7718 10.7416 18.7045 11.5424C18.7004 11.5904 18.6961 11.6419 18.692 11.6972C18.6351 12.4409 18.7461 13.0105 18.9074 13.3895C18.9063 12.7472 18.9996 11.6395 19.5865 10.9252C19.657 10.8402 19.7455 10.7719 19.8456 10.7252C19.9456 10.6785 20.0548 10.6546 20.1653 10.6552C20.2757 10.6558 20.3846 10.6809 20.4842 10.7287C20.5838 10.7764 20.6715 10.8457 20.7411 10.9315C21.3153 11.6479 21.4064 12.7494 21.4051 13.3891C21.5664 13.0101 21.6772 12.4406 21.6204 11.6969C21.6161 11.6419 21.6118 11.5907 21.6079 11.5429C21.5406 10.7426 21.661 10.5529 22.5276 10.0929C22.879 9.90643 23.0156 9.19743 22.8034 8.66168C22.6556 8.28862 22.3019 7.89199 21.4556 8.12299C21.375 8.14447 21.2959 8.17104 21.2186 8.20256C21.3089 8.21009 21.3933 8.25002 21.4564 8.31497C21.5195 8.37992 21.557 8.46549 21.5619 8.5559C21.5668 8.64631 21.5388 8.73545 21.4831 8.80685C21.4275 8.87825 21.3478 8.9271 21.259 8.94437C20.7671 9.04043 20.295 8.84474 19.9825 8.68931C19.9175 8.65706 19.8366 8.61031 19.7428 8.55612C19.4803 8.40437 19.0402 8.15037 18.7335 8.10974C18.654 8.09891 18.5739 8.09335 18.4938 8.09312C17.8101 8.09312 17.5594 8.53487 17.4688 8.81774C17.3074 9.32256 17.4548 9.91881 17.7843 10.0929Z"
      fill={colors.aqua[800]}
    />
  </SvgIcon>
)

export default XRays
