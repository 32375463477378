import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Button)({
  maxWidth: 382,
  display: 'block',
  margin: 'auto',
})

Root.defaultProps = {
  color: 'aqua',
  fullWidth: true,
  size: 'lg',
}
