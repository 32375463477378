import React from 'react'

import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { Person, PersonSummary } from 'src/@types/Person'
import { RecommendationData } from 'src/@types/Recommendation'
import { Shortcut } from 'src/components/Shortcut/Shortcut'

import AppointmentSection from './components/AppointmentSection'
import { DataSection } from './components/DataSection/DataSection'
import DentalIQ from './components/DentalIQ'
import Greeting from './components/Greeting'
import { RecommendationSection } from './components/RecommendationSection/RecommendationSection'
import { SupplementaryInsuranceSection } from './components/SupplementaryInsuranceSection/SupplementaryInsuranceSection'
import { Warnings } from './components/Warnings/Warnings'
import * as S from './Dashboard.styles'

type DashboardProps = {
  person?: Person
  personSummary: Partial<PersonSummary>
  appointments: AppointmentBundle[]
  recommendations: {
    dental: RecommendationData[]
    other: RecommendationData[]
  }
  supplementaryInsurance: {
    quotable: boolean
    dismissed: boolean
  }
  isLoading: {
    appointmentBundlesLoading: boolean
    personLoading: boolean
    dentalRecommendationsLoading: boolean
    otherRecommendationsLoading: boolean
  }
}

const Dashboard = ({
  person,
  personSummary,
  appointments,
  isLoading,
  recommendations,
  supplementaryInsurance,
}: DashboardProps) => {
  const { appointmentBundlesLoading, personLoading, otherRecommendationsLoading, dentalRecommendationsLoading } =
    isLoading

  const showRecommendations =
    !(otherRecommendationsLoading || dentalRecommendationsLoading) &&
    (recommendations.dental.length > 0 || recommendations.other.length > 0)

  const showData =
    personSummary?.hasImages ||
    personSummary?.hasJawFindings ||
    personSummary?.hasPatientHistory ||
    personSummary?.hasPsiDiagnosis

  return (
    <S.Root>
      <Warnings />
      <Greeting person={person} />
      <DentalIQ />
      <AppointmentSection appointments={appointments} processing={appointmentBundlesLoading || personLoading} />
      {showRecommendations && (
        <RecommendationSection
          recommendations={recommendations}
          processing={otherRecommendationsLoading || dentalRecommendationsLoading}
        />
      )}
      {supplementaryInsurance.quotable && !supplementaryInsurance.dismissed && <SupplementaryInsuranceSection />}
      {showData && <DataSection summary={personSummary} />}
      <Shortcut />
    </S.Root>
  )
}

export default Dashboard
