import React from 'react'

import { ButtonProps } from '@mui/material'

import * as S from './LinkButton.styles'

export type LinkButtonProps = { center?: boolean; loading?: boolean } & ButtonProps

const LinkButton = ({ children, center, loading, disabled, ...rest }: LinkButtonProps) => (
  <S.LinkButton center={center} disabled={loading || disabled} {...rest}>
    {children}
  </S.LinkButton>
)

export default LinkButton
