import React from 'react'

import { useTranslation } from 'next-i18next'

import * as S from './Trait.styles'

export const Trait = ({ label, value }: { label: string; value: string }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      {t(label)}: <S.Value>{value}</S.Value>
    </S.Wrapper>
  )
}
