import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const RecommendationTick = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" sx={{ width: 20, height: 20 }}>
      <path
        d="M2 0C1.466 0 0.962937 0.207938 0.585938 0.585938C0.207938 0.963938 0 1.467 0 2V5C0 7.94204 1.83005 10.4544 4.4082 11.4863C4.76204 12.1019 5.32167 12.5754 6 12.8164V16C6 18.209 7.791 20 10 20H14C16.209 20 18 18.209 18 16V15.8262C18.5845 15.6195 19.0907 15.2368 19.4488 14.7307C19.807 14.2246 19.9995 13.62 20 13C20 12.2044 19.6839 11.4413 19.1213 10.8787C18.5587 10.3161 17.7956 10 17 10C16.2044 10 15.4413 10.3161 14.8787 10.8787C14.3161 11.4413 14 12.2044 14 13C14.0009 13.6197 14.1936 14.2239 14.5517 14.7296C14.9098 15.2353 15.4158 15.6177 16 15.8242V16C16 17.105 15.105 18 14 18H10C8.895 18 8 17.105 8 16V12.8164C8.67833 12.5754 9.23796 12.1019 9.5918 11.4863C12.17 10.4535 14 7.93699 14 4.97656V2C14 0.895 13.105 0 12 0H11C10.448 0 10 0.448 10 1C10 1.552 10.448 2 11 2H12V4.77734C12 7.38634 10.099 9.72161 7.50195 9.97461C7.31564 9.99286 7.12947 10.0018 6.94727 10C4.21415 9.97229 2.00102 7.73844 2.00195 4.97656L2 2H3C3.552 2 4 1.552 4 1C4 0.448 3.552 0 3 0H2Z"
        fill={colors.grey[0]}
      />
    </SvgIcon>
  )
}

export default RecommendationTick
