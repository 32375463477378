import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Box)(({ theme }) => ({
  color: colors.grey[800],
  ...theme.typography['Lato Body 2'],
}))

export const Value = styled(Typography)(({ theme }) => ({
  display: 'inline',
  ...theme.typography['Lato Emphasized 2'],
}))
