import { useEffect, useState } from 'react'

import { Nullable } from '@dentalux/common'

import { UAParser } from 'ua-parser-js'

const useDevice = () => {
  const [deviceInfo, setDeviceInfo] = useState<Nullable<UAParser.IResult>>()

  useEffect(() => setDeviceInfo(new UAParser().getResult()), [])

  return deviceInfo
}

export default useDevice
