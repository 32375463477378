import React from 'react'

import { useTranslation } from 'next-i18next'

import { Tooltip, TooltipProps } from '@mui/material'

import { Beta } from './DentalIQBeta.styles'

export const DentalIQBeta = ({ tooltipPlacement }: { tooltipPlacement: TooltipProps['placement'] }) => {
  const { t } = useTranslation()

  return (
    <Tooltip placement={tooltipPlacement} title={t('b2c.dentalIQ.beta.tooltip')} arrow>
      <Beta>{t('b2c.dentalIQ.beta.label')}</Beta>
    </Tooltip>
  )
}
